<template>
  <v-row class="ma-0 pa-0">
    <v-col cols="6">
      <v-autocomplete
        v-model="servicio"
        label="Tipo de Servicio"
        :items="consulta.servicios"
        item-text="servicio"
        dense
        hide-details
        outlined
        return-object
        @change="cambiarServicio"
        :disabled="bloqueoControl"
        :loading="bloqueoControl"
      >
      </v-autocomplete>
    </v-col>
    <v-col cols="6">
      <v-autocomplete
        v-model="id_tipo_documento"
        label="Documento"
        :items="documentosFiltrados"
        dense
        hide-details
        outlined
        item-value="id_tipo_documento"
        item-text="nombre"
        @change="mostrarDocumento"
        :disabled="bloqueoControl"
        :loading="bloqueoControl"
      >
      </v-autocomplete>
    </v-col>
    <v-col cols="12"
      v-if="showPDF"
    >
      <PDFViewer
        :controls="controls"
        :source="url"
        loading-text="Cargando Documento"
        rendering-text="Cargando Documento"
        style="height: 100vh; width: 100%"
        @loading-failed="bloqueoControl = false"
        @rendered="bloqueoControl = false"
        @rendering-failed="bloqueoControl = false"
      />
    </v-col>
  </v-row>
</template>

<script>
//import axios from 'axios'
import { mapState } from 'vuex'
import PDFViewer from 'pdf-viewer-vue/dist/vue2-pdf-viewer'

export default {
  props: {
    consulta: {
      type: Object,
      default: () => ({
        servicios: [],
      })
    }
  },
  components: {
    PDFViewer,
  },
  computed: {
    ...mapState(['token']),
    documentosFiltrados(){
      if(this.servicio == null || this.servicio.ids_tipos_documentos.length == 0) return [];
      return this.documentos.filter(x => this.servicio.ids_tipos_documentos.indexOf(x.id_tipo_documento) >= 0);
    }
  },
	data: () => ({
    showPDF: false,
    servicio: null,
    id_tipo_documento: -1,
    controls: [
      'print',
      'rotate',
      'zoom',
      'catalog',
      'switchPage',
    ],
    documentos: [
      { id_tipo_documento: 2, nombre:  "Receta Medica - Paciente" },
      { id_tipo_documento: 3, nombre:  "Referencia Medica" },
      { id_tipo_documento: 4, nombre:  "Nota Médica de Evolución" },
      { id_tipo_documento: 5, nombre:  "Historia Clínica Médica de Prevención" },
      { id_tipo_documento: 6, nombre:  "Historia Clínica Médica para Embarazada" },
      { id_tipo_documento: 7, nombre:  "Historia Clínica Médica para Pediatrica" },
      { id_tipo_documento: 8, nombre:  "Autorización Realización de Estudios" },
      { id_tipo_documento: 9, nombre:  "Autorización Detección de Cáncer de Mama" },
      { id_tipo_documento: 10, nombre: "Autorización Prueba Rápida de SARS-COV-2" },
      { id_tipo_documento: 11, nombre: "Receta Dental - Farmacia "},
      { id_tipo_documento: 12, nombre: "Receta Dental - Paciente"},
      { id_tipo_documento: 13, nombre: "Consentimiento de Procedimientos Dentales"},
      { id_tipo_documento: 14, nombre: "Historia Clínica Odontológica"},
      { id_tipo_documento: 15, nombre: "Odontología Preventiva"},
      { id_tipo_documento: 16, nombre: "Recomendación Nutricional"},
      { id_tipo_documento: 17, nombre: "Entrevista Nutricional"},
    ],
    url: null,
    bloqueoControl: false,
  }),
  methods: {
    cambiarServicio(){
      this.id_tipo_documento = -1;
    },
    mostrarDocumento(){
      this.showPDF = false;
      this.url = null;
      this.bloqueoControl = true;
      setTimeout(() => {
        this.showPDF = true;
      }, 250);
      setTimeout(() => {
        this.url = `/api/Historial/ObtenerDocumento/${this.servicio.id_paciente_servicio}/${this.id_tipo_documento}?jwt=${this.token}#toolbar=0&navpanes=0&scrollbar=0`;
      }, 1000);
    },
  }
}
</script>