<template>
  <div class="pa-3 ma-3">
    <v-row>
      <v-col cols="4">
      </v-col>
      <v-col cols="4">
      </v-col>
      <v-col cols="4">
        <DescargarConcentrado>
        </DescargarConcentrado>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        Rango de Fechas:
        <date-picker v-model="busquedaItem.rango" range valueType="format" format="DD-MM-YYYY" @change="getItems"></date-picker>
      </v-col>
      <v-col cols="6">
        <v-autocomplete
          v-model="busquedaItem.id_unidad_medica"
          :items="unidades_medicas"
          outlined
          label="Unidad Medica (Opcional)"
          :item-text="nombreUnidad"
          item-value="id_unidad_medica"
          :loading="loadingUnidadesMedicas"
          dense
          hide-details
          @change="getItems"
          clearable
          :disabled="loadingUnidadesMedicas || loading"
        >
        </v-autocomplete>
      </v-col>
      <v-col cols="3">
        <v-autocomplete
          v-model="busquedaItem.id_proyecto"
          :items="proyectos"
          outlined
          label="Proyecto (Opcional)"
          :item-text="nombreProyecto"
          item-value="id_proyecto"
          :loading="loadingProyectos"
          @change="getItems"
          :disabled="loadingProyectos || loading"
          dense
          hide-details
          clearable
        >
        </v-autocomplete>
      </v-col>
      <v-col cols="3">
        <v-autocomplete
          v-model="busquedaItem.id_estado"
          :items="estados"
          outlined
          label="Estado (Opcional)"
          :item-text="nombreEstado"
          item-value="id_estado"
          @change="getMunicipios(); getLocalidadesAtencion();getItems();"
          :loading="loadingEstados"
          clearable
          :disabled="loadingEstados || loading"
          dense
          hide-details
        >
        </v-autocomplete>
      </v-col>
      <v-col cols="3">
        <v-autocomplete
          v-model="busquedaItem.id_municipio"
          :items="municipios"
          item-text="nombre"
          item-value="id_municipio"
          outlined
          label="Municipio (Opcional)"
          :loading="loadingMunicipios"
          :disabled="busquedaItem.id_estado == null || busquedaItem.id_estado <= 0 || loadingMunicipios"
          clearable
          @change="getItems"
          dense
          hide-details
        >
        </v-autocomplete>
      </v-col>
      <v-col cols="3">
        <v-autocomplete
          v-model="busquedaItem.id_localidad_atencion"
          outlined
          :name="uuidv4"
          :items="localidadesAtencion"
          :loading="loadingLocalidadesAtencion"
          label="Localidad de Atención (Opcional)"
          item-value="id_localidad_atencion"
          item-text="nombre"
          :disabled="busquedaItem.id_estado == null || busquedaItem.id_estado <= 0 || loadingLocalidadesAtencion || loading"
          clearable
          @change="getItems"
          dense
          hide-details
        >
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-row v-if="!loading">
      <v-col cols="12" md="3" v-if="loaded">
        <v-card
          class="mx-auto my-1"
          max-width="100%"
          style="background-color: #8E24AA"
        >
          <v-card-title style="color: #fff" class="d-flex justify-center">
            <v-icon style="font-size: 22px; color: #fff">
              mdi-thermometer
            </v-icon>
            &nbsp; # Somatometría: <br>

            <ICountUp
              :duration="5000"
              :delay="1000"
              :endVal="num_somatometria"
              class="text-h5"
              style="color: #fff; font-weight: bold"
              :options="countupOptions"
            />
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="12" md="3" v-if="loaded">
        <v-card
          class="mx-auto my-1"
          max-width="100%"
          style="background-color: #009688"
        >
          <v-card-title style="color: #fff" class="d-flex justify-center">
            <v-icon style="font-size: 22px; color: #fff">
              mdi-medical-bag
            </v-icon>
            &nbsp; # Consultas Médicas: <br>
            <ICountUp
              :duration="5000"
              :delay="1000"
              :endVal="num_consultas_medicas"
              class="text-h5"
              style="color: #fff; font-weight: bold"
              :options="countupOptions"
            />
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="12" md="3" v-if="loaded">
        <v-card
          class="mx-auto my-1"
          max-width="100%"
          style="background-color: #4CAF50"
        >
          <v-card-title style="color: #fff" class="d-flex justify-center">
            <v-icon style="font-size: 22px; color: #fff">
              mdi-tooth-outline
            </v-icon>
            &nbsp; # Consultas Dentales: <br>
            <ICountUp
              :duration="5000"
              :delay="1000"
              :endVal="num_consultas_dentales"
              class="text-h5"
              style="color: #fff; font-weight: bold"
              :options="countupOptions"
            />
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="12" md="3" v-if="loaded">
        <v-card
          class="mx-auto my-1"
          max-width="100%"
          style="background-color: #FF9800"
        >
          <v-card-title style="color: #fff" class="d-flex justify-center">
            <v-icon style="font-size: 22px; color: #fff">
              mdi-food-apple-outline
            </v-icon>
            &nbsp; # Consultas Nutricionales: <br>
            <ICountUp
              :duration="5000"
              :delay="1000"
              :endVal="num_consultas_nutricionales"
              class="text-h5"
              style="color: #fff; font-weight: bold"
              :options="countupOptions"
            />
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="12" md="3" v-if="loaded">
        <v-card
          class="mx-auto my-1"
          max-width="100%"
          style="background-color: #1E88E5"
        >
          <v-card-title style="color: #fff" class="d-flex justify-center">
            <v-icon style="font-size: 22px; color: #fff">
              mdi-flask-outline
            </v-icon>
            &nbsp; # Detecciones de químicas secas: <br>
            <ICountUp
              :duration="5000"
              :delay="1000"
              :endVal="num_quimicas_secas"
              class="text-h5"
              style="color: #fff; font-weight: bold"
              :options="countupOptions"
            />
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="12" md="3" v-if="loaded">
        <v-card
          class="mx-auto my-1"
          max-width="100%"
          style="background-color: #00ACC1"
        >
          <v-card-title style="color: #fff" class="d-flex justify-center">
            <v-icon style="font-size: 22px; color: #fff">
              mdi-radiology-box
            </v-icon>
            &nbsp; # Ultrasonidos: <br>
            <ICountUp
              :duration="5000"
              :delay="1000"
              :endVal="num_ultrasonidos"
              class="text-h5"
              style="color: #fff; font-weight: bold"
              :options="countupOptions"
            />
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="12" md="3" v-if="loaded">
        <v-card
          class="mx-auto my-1"
          max-width="100%"
          style="background-color: #FFB300"
        >
          <v-card-title style="color: #fff" class="d-flex justify-center">
            <v-icon style="font-size: 22px; color: #fff">
              mdi-heart-pulse
            </v-icon>
            &nbsp; # Electrocardiogramas: <br>
            <ICountUp
              :duration="5000"
              :delay="1000"
              :endVal="num_electrocardiogramas"
              class="text-h5"
              style="color: #fff; font-weight: bold"
              :options="countupOptions"
            />
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="12" md="3" v-if="loaded">
        <v-card
          class="mx-auto my-1"
          max-width="100%"
          style="background-color: #5D4037"
        >
          <v-card-title style="color: #fff" class="d-flex justify-center">
            <v-icon style="font-size: 22px; color: #fff">
              mdi-account-voice
            </v-icon>
            &nbsp; # Talleres promoción a la salud: <br>
            <ICountUp
              :duration="5000"
              :delay="1000"
              :endVal="num_talleres"
              class="text-h5"
              style="color: #fff; font-weight: bold"
              :options="countupOptions"
            />
          </v-card-title>
        </v-card>
      </v-col>

    </v-row>
    <v-row v-if="!loading">
      <!--
      <v-col cols="6" v-if="loaded">
        <v-card>
          <v-card-title class="justify-content-center">Pacientes por Edad</v-card-title>
          <v-card-text>
            <chart-donut
              ref="pacientes_edades"
            >
            </chart-donut>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6" v-if="loaded">
        <v-card>
          <v-card-title class="justify-content-center">Pacientes por Servicio</v-card-title>
          <v-card-text>
            <chart-bar
              ref="pacientes_servicios"
            >
            </chart-bar>
          </v-card-text>
        </v-card>
      </v-col>
      -->
      <v-col cols="12"
        v-if="tablas.length > 0"
      >
        <v-btn
            block
            color="primary"
            text
            @click="descargarExcel"
          >
            <v-icon>
              mdi-download
            </v-icon>
            Descargar Incidencias
          </v-btn>
      </v-col>

      <v-col cols="12" class="ma-0 pa-0">
        <v-expansion-panels
          v-model="panel"
          multiple
        >
          <v-expansion-panel v-for="(tabla, idx) in tablas" :key="'tabla-'+idx">
            <v-expansion-panel-header>
              <div class="text-subtitle-1 text-center">{{tabla.title}}</div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-data-table-plus
                v-if="!loading"
                :headers="tabla.headers"
                :items="tabla.items"
                :groups="tabla.groups"
                :rows="tabla.rows"
              >
              </v-data-table-plus>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel v-if="!loading && metas != null && metas.length > 0">
            <v-expansion-panel-header>
              <div class="text-subtitle-1 text-center">Metas</div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <template
                v-for="(meta, idx) in metas"
              >
                <v-data-table-metas
                  :key="'meta-'+idx"
                  :headers="meta.headers"
                  :items="meta.items"
                  :groups="meta.groups"
                  :rows="meta.rows"
                  :title="meta.title"
                  :supertitle="meta.super_title"
                >
                </v-data-table-metas>
                <br :key="'meta-a-'+idx">
                <br :key="'meta-b-'+idx">
              </template>
              
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="12" class="text-center">
        <br>
        <v-progress-circular :size="100" color="primary" indeterminate></v-progress-circular>
        <br>
        <br>
        <div class="text-h5">Cargando</div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ICountUp from "vue-countup-v2";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es';
import axios from 'axios'
//import ChartDonut from '@/components/Charts/Donut.vue'
//import ChartBar from '@/components/Charts/Bar.vue'
import VDataTablePlus from '@/components/VDataTablePlus.vue';
import VDataTableMetas from '@/components/VDataTableMetas.vue';
import { v4 } from 'uuid';
import { mapState } from 'vuex'
import DescargarConcentrado from './DescargarConcentrado.vue';

export default {
  components: {
    ICountUp,
    DatePicker,
    //ChartDonut,
    //ChartBar,
    VDataTablePlus,
    VDataTableMetas,
    DescargarConcentrado
  },
  data: () => ({
    panel:[],
    tablas: [],
    busquedaItem: {
      rango: null,
      id_unidad_medica: null,
      id_estado: null,
      id_municipio: null,
      id_localidad_atencion: null,
      id_proyecto: null,
    },
    loadingUnidadesMedicas: false,
    unidades_medicas: [],
    loading: false,
    loaded: false,
    countupOptions: {
      suffix: ''
    },
    num_pacientes: 0,
    num_consultas_medicas: 0,
    num_consultas_dentales: 0,
    num_consultas_nutricionales: 0,
    num_quimicas_secas: 0,
    num_somatometria: 0,
    num_ultrasonidos: 0,
    num_electrocardiogramas: 0,
    num_talleres: 0,
    estados: [],
    loadingEstados: false,
    municipios: [],
    loadingMunicipios: false,
    localidadesAtencion:[],
    loadingLocalidadesAtencion: false,
    proyectos: [],
    loadingProyectos: false,

    metas: null,
  }),
  mounted() {
    this.getUnidadesMedicas();
    this.getEstados();
    this.getProyectos();
  },
  computed: {
    ...mapState(['token']),
  },
  methods: {
    uuidv4(){
      return v4();
    },
    nombreEstado: estado => `[${estado.codigo}] - ${estado.nombre}`,
    nombreUnidad: item => `[${item.codigo}] - ${item.nombre}`,
    nombreProyecto: item => `[${item.codigo}] - ${item.nombre}`,
    getUnidadesMedicas(){
      this.loadingUnidadesMedicas = true;
      axios
      .get('/Catalogos/ListarUnidadesMedicas')
      .then(response => {
        this.unidades_medicas = response.data;
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.loadingUnidadesMedicas = false;
      })
    },
    getEstados(){
      this.loadingEstados = true;
      axios
        .get('/Catalogos/ListarEstados')
        .then(response => {
          this.estados = response.data;
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loadingEstados = false;
        })
    },
    getMunicipios(){
      if(this.busquedaItem.id_estado == null) return;
      this.loadingMunicipios = true;
      axios
        .get(`/Catalogos/ListarMunicipios/${this.busquedaItem.id_estado}`)
        .then(response => {
          this.municipios = response.data;
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loadingMunicipios = false;
        })
    },
    getLocalidadesAtencion(){
      if(this.busquedaItem.id_estado == null) return;
      this.loadingLocalidadesAtencion = true;
      axios
        .get(`/Catalogos/ListarLocalidadesAtencion/${this.busquedaItem.id_estado}`)
        .then(response => {
          this.localidadesAtencion = response.data;
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loadingLocalidadesAtencion = false;
        })
    },
    getProyectos(){
      this.loadingProyectos = true;
      axios
        .get('/Catalogos/ListarProyectos?solo_activos=false')
        .then(response => {
          this.proyectos = response.data;
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loadingProyectos = false;
        })
    },
    getItems(){
      if(this.busquedaItem.rango == null || this.busquedaItem.rango.length != 2) return;

      this.loading = true;
      this.tablas = [];
      this.panel = [];
      this.loaded = false;

      const url = new URL(`http://localhost/Incidencias/ListarIncidencias/${this.busquedaItem.rango[0]}/${this.busquedaItem.rango[1]}`);
      
      if(this.busquedaItem.id_unidad_medica != null) url.searchParams.append("id_unidad_medica", this.busquedaItem.id_unidad_medica);
      if(this.busquedaItem.id_estado != null) url.searchParams.append("id_estado", this.busquedaItem.id_estado);
      if(this.busquedaItem.id_municipio != null) url.searchParams.append("id_municipio", this.busquedaItem.id_municipio);
      if(this.busquedaItem.id_localidad_atencion != null) url.searchParams.append("id_localidad_atencion", this.busquedaItem.id_localidad_atencion);
      if(this.busquedaItem.id_proyecto != null) url.searchParams.append("id_proyecto", this.busquedaItem.id_proyecto);

      var url_string = url.href.replace('http://localhost', '');
      
      axios.get(url_string)
        .then(response => {
          this.num_pacientes = response.data.num_pacientes;
          this.num_consultas_medicas = response.data.num_consultas_medicas;
          this.num_consultas_dentales = response.data.num_consultas_dentales;
          this.num_consultas_nutricionales = response.data.num_consultas_nutricionales;
          this.num_quimicas_secas = response.data.num_quimicas_secas;
          this.num_somatometria = response.data.num_somatometria;
          this.num_ultrasonidos = response.data.num_ultrasonidos;
          this.num_electrocardiogramas = response.data.num_electrocardiogramas;
          this.num_talleres = response.data.num_talleres;
          this.tablas = response.data.tablas;
          this.metas = response.data.metas;
          this.loaded = true;
          /*
          setTimeout(() => {
            this.$refs.pacientes_edades.setData(response.data.edades_cantidad, response.data.edades_label);
            this.$refs.pacientes_servicios.setData(response.data.servicios_cantidad, response.data.servicios_label);
          }, 250);
          */
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        })
    },
    getStyle(key){
      if(key == 'total') return 'orange-cell text-center';
      if(key.startsWith('subtotal')) return 'brow-cell text-center';
      else return 'text-center';
    },
    descargarExcel(){
      const url = new URL(`http://localhost/Incidencias/DescargarIncidencias/${this.busquedaItem.rango[0]}/${this.busquedaItem.rango[1]}`);
      url.searchParams.append("jwt", this.token);

      if(this.busquedaItem.id_unidad_medica != null) url.searchParams.append("id_unidad_medica", this.busquedaItem.id_unidad_medica);
      if(this.busquedaItem.id_estado != null) url.searchParams.append("id_estado", this.busquedaItem.id_estado);
      if(this.busquedaItem.id_municipio != null) url.searchParams.append("id_municipio", this.busquedaItem.id_municipio);
      if(this.busquedaItem.id_localidad_atencion != null) url.searchParams.append("id_localidad_atencion", this.busquedaItem.id_localidad_atencion);
      if(this.busquedaItem.id_proyecto != null) url.searchParams.append("id_proyecto", this.busquedaItem.id_proyecto);
      
      var url_string = url.href.replace('http://localhost', '/api');

      window.open(url_string, '_blank');
    },
  }
}
</script>