<template>
  <v-card>
    <v-card-text
      v-if="show"
      class="pa-1"
    >
      <v-row
        class="ma-0 pa-0"
      >
        <v-col cols="2"
          class="ma-0 pa-0 align-self-center"
        >
          <v-col cols="12" class="py-0 my-0 text-h6 text-center">
            {{ viewItem.proyecto }}
          </v-col>
          <v-col cols="12" class="text-center py-0">
            <v-progress-circular
              :rotate="-90"
              :size="150"
              :width="15"
              :value="viewItem.porcentaje_general"
              :color="getBackgroundColorFromValue(viewItem.porcentaje_general,0)"
              style="pointer-events: none"
            >
              <div
                class="text-subtitle-1 font-weight-bold"
                :style="{ color: getBackgroundColorFromValue(viewItem.porcentaje_general,0) }"
              >
                {{ viewItem.porcentaje_general.toFixed(2) }} %
              </div>
              
            </v-progress-circular>
          </v-col>
        </v-col>

        <v-col cols="10">
          <v-row class="ma-0 pa-0">
            <!-- Consulta Médica -->
            <v-col cols="3" class="py-1">
              <v-row class="ma-0 pa-0">
                <v-col cols="12" class="ma-0 pa-0 font-weight-bold">
                  Consulta Médica
                </v-col>
                <v-col cols="7" class="ma-0 pa-0 align-self-end pr-1">
                  <v-progress-linear
                    v-model="viewItem.consulta_medica_porcentaje"
                    :height="height_progress"
                    :color="getBackgroundColorFromValue(viewItem.consulta_medica_porcentaje, 1)"
                    style="pointer-events: none"
                  >
                    <strong :style="{ color: getTextColorFromValue(viewItem.consulta_medica_porcentaje, 1) }">
                      {{ viewItem.consulta_medica_porcentaje.toFixed(2) }}%
                    </strong>
                  </v-progress-linear>
                </v-col>
                <v-col cols="5" class="ma-0 pa-0 pl-1 text-center">
                  {{ viewItem.consulta_medica_texto }}
                </v-col>
              </v-row>
            </v-col>

            <!-- Intervenciones Odontológicas -->
            <v-col cols="3" class="py-1">
              <v-row class="ma-0 pa-0">
                <v-col cols="12" class="ma-0 pa-0 font-weight-bold">
                  Intervenciones Odontológicas
                </v-col>
                <v-col cols="7" class="ma-0 pa-0 align-self-end pr-1">
                  <v-progress-linear
                    v-model="viewItem.intervenciones_odontologicas_porcentaje"
                    :height="height_progress"
                    :color="getBackgroundColorFromValue(viewItem.intervenciones_odontologicas_porcentaje, 2)"
                    style="pointer-events: none"
                  >
                    <strong :style="{ color: getTextColorFromValue(viewItem.intervenciones_odontologicas_porcentaje, 2) }">
                      {{ viewItem.intervenciones_odontologicas_porcentaje.toFixed(2) }}%
                    </strong>
                  </v-progress-linear>
                </v-col>
                <v-col cols="5" class="ma-0 pa-0 pl-1 text-center">
                  {{ viewItem.intervenciones_odontologicas_texto }}
                </v-col>
              </v-row>
            </v-col>

            <!-- Consulta Dental -->
            <v-col cols="3" class="py-1">
              <v-row class="ma-0 pa-0">
                <v-col cols="12" class="ma-0 pa-0 font-weight-bold">
                  Consulta Dental
                </v-col>
                <v-col cols="7" class="ma-0 pa-0 align-self-end pr-1">
                  <v-progress-linear
                    v-model="viewItem.consulta_dental_porcentaje"
                    :height="height_progress"
                    :color="getBackgroundColorFromValue(viewItem.consulta_dental_porcentaje, 3)"
                    style="pointer-events: none"
                  >
                    <strong :style="{ color: getTextColorFromValue(viewItem.consulta_dental_porcentaje, 3) }">
                      {{ viewItem.consulta_dental_porcentaje.toFixed(2) }}%
                    </strong>
                  </v-progress-linear>
                </v-col>
                <v-col cols="5" class="ma-0 pa-0 pl-1 text-center">
                  {{ viewItem.consulta_dental_texto }}
                </v-col>
              </v-row>
            </v-col>

            <!-- Detecciones Químicas Secas -->
            <v-col cols="3" class="py-1">
              <v-row class="ma-0 pa-0">
                <v-col cols="12" class="ma-0 pa-0 font-weight-bold">
                  Detecciones Químicas Secas
                </v-col>
                <v-col cols="7" class="ma-0 pa-0 align-self-end pr-1">
                  <v-progress-linear
                    v-model="viewItem.detecciones_quimicas_secas_porcentaje"
                    :height="height_progress"
                    :color="getBackgroundColorFromValue(viewItem.detecciones_quimicas_secas_porcentaje, 4)"
                    style="pointer-events: none"
                  >
                    <strong :style="{ color: getTextColorFromValue(viewItem.detecciones_quimicas_secas_porcentaje, 4) }">
                      {{ viewItem.detecciones_quimicas_secas_porcentaje.toFixed(2) }}%
                    </strong>
                  </v-progress-linear>
                </v-col>
                <v-col cols="5" class="ma-0 pa-0 pl-1 text-center">
                  {{ viewItem.detecciones_quimicas_secas_texto }}
                </v-col>
              </v-row>
            </v-col>

            <!-- T/A -->
            <v-col cols="3" class="py-1">
              <v-row class="ma-0 pa-0">
                <v-col cols="12" class="ma-0 pa-0 font-weight-bold">
                  T/A
                </v-col>
                <v-col cols="7" class="ma-0 pa-0 align-self-end pr-1">
                  <v-progress-linear
                    v-model="viewItem.ta_porcentaje"
                    :height="height_progress"
                    :color="getBackgroundColorFromValue(viewItem.ta_porcentaje, 5)"
                    style="pointer-events: none"
                  >
                    <strong :style="{ color: getTextColorFromValue(viewItem.ta_porcentaje, 5) }">
                      {{ viewItem.ta_porcentaje.toFixed(2) }}%
                    </strong>
                  </v-progress-linear>
                </v-col>
                <v-col cols="5" class="ma-0 pa-0 pl-1 text-center">
                  {{ viewItem.ta_texto }}
                </v-col>
              </v-row>
            </v-col>

            <!-- Somatometría -->
            <v-col cols="3" class="py-1">
              <v-row class="ma-0 pa-0">
                <v-col cols="12" class="ma-0 pa-0 font-weight-bold">
                  Somatometría
                </v-col>
                <v-col cols="7" class="ma-0 pa-0 align-self-end pr-1">
                  <v-progress-linear
                    v-model="viewItem.somatometria_porcentaje"
                    :height="height_progress"
                    :color="getBackgroundColorFromValue(viewItem.somatometria_porcentaje, 6)"
                    style="pointer-events: none"
                  >
                    <strong :style="{ color: getTextColorFromValue(viewItem.somatometria_porcentaje, 6) }">
                      {{ viewItem.somatometria_porcentaje.toFixed(2) }}%
                    </strong>
                  </v-progress-linear>
                </v-col>
                <v-col cols="5" class="ma-0 pa-0 pl-1 text-center">
                  {{ viewItem.somatometria_texto }}
                </v-col>
              </v-row>
            </v-col>

            <!-- Ultrasonidos -->
            <v-col cols="3" class="py-1">
              <v-row class="ma-0 pa-0">
                <v-col cols="12" class="ma-0 pa-0 font-weight-bold">
                  Ultrasonidos
                </v-col>
                <v-col cols="7" class="ma-0 pa-0 align-self-end pr-1">
                  <v-progress-linear
                    v-model="viewItem.ultrasonidos_porcentaje"
                    :height="height_progress"
                    :color="getBackgroundColorFromValue(viewItem.ultrasonidos_porcentaje, 7)"
                    style="pointer-events: none"
                  >
                    <strong :style="{ color: getTextColorFromValue(viewItem.ultrasonidos_porcentaje, 7) }">
                      {{ viewItem.ultrasonidos_porcentaje.toFixed(2) }}%
                    </strong>
                  </v-progress-linear>
                </v-col>
                <v-col cols="5" class="ma-0 pa-0 pl-1 text-center">
                  {{ viewItem.ultrasonidos_texto }}
                </v-col>
              </v-row>
            </v-col>

            <!-- Electrocardiogramas -->
            <v-col cols="3" class="py-1">
              <v-row class="ma-0 pa-0">
                <v-col cols="12" class="ma-0 pa-0 font-weight-bold">
                  Electrocardiogramas
                </v-col>
                <v-col cols="7" class="ma-0 pa-0 align-self-end pr-1">
                  <v-progress-linear
                    v-model="viewItem.electrocardiogramas_porcentaje"
                    :height="height_progress"
                    :color="getBackgroundColorFromValue(viewItem.electrocardiogramas_porcentaje, 8)"
                    style="pointer-events: none"
                  >
                    <strong :style="{ color: getTextColorFromValue(viewItem.electrocardiogramas_porcentaje, 8) }">
                      {{ viewItem.electrocardiogramas_porcentaje.toFixed(2) }}%
                    </strong>
                  </v-progress-linear>
                </v-col>
                <v-col cols="5" class="ma-0 pa-0 pl-1 text-center">
                  {{ viewItem.electrocardiogramas_texto }}
                </v-col>
              </v-row>
            </v-col>

            <!-- Pruebas Rápidas COVID-19 -->
            <v-col cols="3" class="py-1">
              <v-row class="ma-0 pa-0">
                <v-col cols="12" class="ma-0 pa-0 font-weight-bold">
                  Pruebas Rápidas COVID-19
                </v-col>
                <v-col cols="7" class="ma-0 pa-0 align-self-end pr-1">
                  <v-progress-linear
                    v-model="viewItem.covid19_porcentaje"
                    :height="height_progress"
                    :color="getBackgroundColorFromValue(viewItem.covid19_porcentaje, 9)"
                    style="pointer-events: none"
                  >
                    <strong :style="{ color: getTextColorFromValue(viewItem.covid19_porcentaje, 9) }">
                      {{ viewItem.covid19_porcentaje.toFixed(2) }}%
                    </strong>
                  </v-progress-linear>
                </v-col>
                <v-col cols="5" class="ma-0 pa-0 pl-1 text-center">
                  {{ viewItem.covid19_texto }}
                </v-col>
              </v-row>
            </v-col>

            <!-- Consultas de Nutrición -->
            <v-col cols="3" class="py-1">
              <v-row class="ma-0 pa-0">
                <v-col cols="12" class="ma-0 pa-0 font-weight-bold">
                  Consultas de Nutrición
                </v-col>
                <v-col cols="7" class="ma-0 pa-0 align-self-end pr-1">
                  <v-progress-linear
                    v-model="viewItem.consulta_nutricion_porcentaje"
                    :height="height_progress"
                    :color="getBackgroundColorFromValue(viewItem.consulta_nutricion_porcentaje, 10)"
                    style="pointer-events: none"
                  >
                    <strong :style="{ color: getTextColorFromValue(viewItem.consulta_nutricion_porcentaje, 10) }">
                      {{ viewItem.consulta_nutricion_porcentaje.toFixed(2) }}%
                    </strong>
                  </v-progress-linear>
                </v-col>
                <v-col cols="5" class="ma-0 pa-0 pl-1 text-center">
                  {{ viewItem.consulta_nutricion_texto }}
                </v-col>
              </v-row>
            </v-col>

            <!-- Pláticas Alimentación Sana (Nutrición) -->
            <v-col cols="3" class="py-1">
              <v-row class="ma-0 pa-0">
                <v-col cols="12" class="ma-0 pa-0 font-weight-bold">
                  Pláticas Alimentación Sana (Nutrición)
                </v-col>
                <v-col cols="7" class="ma-0 pa-0 align-self-end pr-1">
                  <v-progress-linear
                    v-model="viewItem.platicas_alimentacion_porcentaje"
                    :height="height_progress"
                    :color="getBackgroundColorFromValue(viewItem.platicas_alimentacion_porcentaje, 11)"
                    style="pointer-events: none"
                  >
                    <strong :style="{ color: getTextColorFromValue(viewItem.platicas_alimentacion_porcentaje, 11) }">
                      {{ viewItem.platicas_alimentacion_porcentaje.toFixed(2) }}%
                    </strong>
                  </v-progress-linear>
                </v-col>
                <v-col cols="5" class="ma-0 pa-0 pl-1 text-center">
                  {{ viewItem.platicas_alimentacion_texto }}
                </v-col>
              </v-row>
            </v-col>

            <!-- Pláticas Salud Bucal (Dental) -->
            <v-col cols="3" class="py-1">
              <v-row class="ma-0 pa-0">
                <v-col cols="12" class="ma-0 pa-0 font-weight-bold">
                  Pláticas Salud Bucal (Dental)
                </v-col>
                <v-col cols="7" class="ma-0 pa-0 align-self-end pr-1">
                  <v-progress-linear
                    v-model="viewItem.platica_salud_bucal_porcentaje"
                    :height="height_progress"
                    :color="getBackgroundColorFromValue(viewItem.platica_salud_bucal_porcentaje, 12)"
                    style="pointer-events: none"
                  >
                    <strong :style="{ color: getTextColorFromValue(viewItem.platica_salud_bucal_porcentaje, 12) }">
                      {{ viewItem.platica_salud_bucal_porcentaje.toFixed(2) }}%
                    </strong>
                  </v-progress-linear>
                </v-col>
                <v-col cols="5" class="ma-0 pa-0 pl-1 text-center">
                  {{ viewItem.platica_salud_bucal_texto }}
                </v-col>
              </v-row>
            </v-col>

            <!-- Acciones Salud Bucodental -->
            <v-col cols="3" class="py-1">
              <v-row class="ma-0 pa-0">
                <v-col cols="12" class="ma-0 pa-0 font-weight-bold">
                  Acciones Salud Bucodental
                </v-col>
                <v-col cols="7" class="ma-0 pa-0 align-self-end pr-1">
                  <v-progress-linear
                    v-model="viewItem.acciones_salud_bucocal_porcentaje"
                    :height="height_progress"
                    :color="getBackgroundColorFromValue(viewItem.acciones_salud_bucocal_porcentaje, 13)"
                    style="pointer-events: none"
                  >
                    <strong :style="{ color: getTextColorFromValue(viewItem.acciones_salud_bucocal_porcentaje, 13) }">
                      {{ viewItem.acciones_salud_bucocal_porcentaje.toFixed(2) }}%
                    </strong>
                  </v-progress-linear>
                </v-col>
                <v-col cols="5" class="ma-0 pa-0 pl-1 text-center">
                  {{ viewItem.acciones_salud_bucocal_texto }}
                </v-col>
              </v-row>
            </v-col>

            <!-- Pláticas de Promoción a la Salud -->
            <v-col cols="3" class="py-1">
              <v-row class="ma-0 pa-0">
                <v-col cols="12" class="ma-0 pa-0 font-weight-bold">
                  Pláticas de Promoción a la Salud
                </v-col>
                <v-col cols="7" class="ma-0 pa-0 align-self-end pr-1">
                  <v-progress-linear
                    v-model="viewItem.platicas_promocion_salud_porcentaje"
                    :height="height_progress"
                    :color="getBackgroundColorFromValue(viewItem.platicas_promocion_salud_porcentaje, 14)"
                    style="pointer-events: none"
                  >
                    <strong :style="{ color: getTextColorFromValue(viewItem.platicas_promocion_salud_porcentaje, 14) }">
                      {{ viewItem.platicas_promocion_salud_porcentaje.toFixed(2) }}%
                    </strong>
                  </v-progress-linear>
                </v-col>
                <v-col cols="5" class="ma-0 pa-0 pl-1 text-center">
                  {{ viewItem.platicas_promocion_salud_texto }}
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>

      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    show: true,
    height_progress: 15,
    viewItem: {
      proyecto: "",
      porcentaje_general: 0.0,
      consulta_medica_porcentaje: 0.0,
      intervenciones_odontologicas_porcentaje: 0.0,
      consulta_dental_porcentaje: 0.0,
      detecciones_quimicas_secas_porcentaje: 0.0,
      ta_porcentaje: 0.0,
      somatometria_porcentaje: 0.0,
      ultrasonidos_porcentaje: 0.0,
      electrocardiogramas_porcentaje: 0.0,
      covid19_porcentaje: 0.0,
      consulta_nutricion_porcentaje: 0.0,
      platicas_alimentacion_porcentaje: 0.0,
      platica_salud_bucal_porcentaje: 0.0,
      acciones_salud_bucocal_porcentaje: 0.0,
      platicas_promocion_salud_porcentaje: 0.0,
      consulta_medica_texto: "",
      intervenciones_odontologicas_texto: "",
      consulta_dental_texto: "",
      detecciones_quimicas_secas_texto: "",
      ta_texto: "",
      somatometria_texto: "",
      ultrasonidos_texto: "",
      electrocardiogramas_texto: "",
      covid19_texto: "",
      consulta_nutricion_texto: "",
      platicas_alimentacion_texto: "",
      platica_salud_bucal_texto: "",
      acciones_salud_bucocal_texto: "",
      platicas_promocion_salud_texto: "",
    },
    defaultViewItem: {
      proyecto: "",
      porcentaje_general: 0.0,
      consulta_medica_porcentaje: 0.0,
      intervenciones_odontologicas_porcentaje: 0.0,
      consulta_dental_porcentaje: 0.0,
      detecciones_quimicas_secas_porcentaje: 0.0,
      ta_porcentaje: 0.0,
      somatometria_porcentaje: 0.0,
      ultrasonidos_porcentaje: 0.0,
      electrocardiogramas_porcentaje: 0.0,
      covid19_porcentaje: 0.0,
      consulta_nutricion_porcentaje: 0.0,
      platicas_alimentacion_porcentaje: 0.0,
      platica_salud_bucal_porcentaje: 0.0,
      acciones_salud_bucocal_porcentaje: 0.0,
      platicas_promocion_salud_porcentaje: 0.0,
      consulta_medica_texto: "",
      intervenciones_odontologicas_texto: "",
      consulta_dental_texto: "",
      detecciones_quimicas_secas_texto: "",
      ta_texto: "",
      somatometria_texto: "",
      ultrasonidos_texto: "",
      electrocardiogramas_texto: "",
      covid19_texto: "",
      consulta_nutricion_texto: "",
      platicas_alimentacion_texto: "",
      platica_salud_bucal_texto: "",
      acciones_salud_bucocal_texto: "",
      platicas_promocion_salud_texto: "",
    },
    limites: [
      { limiteSuperior: 100, limiteMedio: 85, limiteInferior: 70 }, // General
      { limiteSuperior: 100, limiteMedio: 85, limiteInferior: 70 }, // OK - Consulta Medica
      { limiteSuperior: 100, limiteMedio: 78, limiteInferior: 60 }, // OK - Intervenciones Odontologicas
      { limiteSuperior: 100, limiteMedio: 85, limiteInferior: 70 }, // OK - Consulta Dental
      { limiteSuperior: 100, limiteMedio: 85, limiteInferior: 70 }, // OK - Detecciones Quimicas Secas
      { limiteSuperior: 100, limiteMedio: 85, limiteInferior: 70 }, // OK ' T/A
      { limiteSuperior: 100, limiteMedio: 85, limiteInferior: 70 }, // OK - Somatometria
      { limiteSuperior: 100, limiteMedio: 80, limiteInferior: 60 }, // OK - Ultrasonidos
      { limiteSuperior: 100, limiteMedio: 80, limiteInferior: 60 }, // OK - Electrocardiogramas
      { limiteSuperior: 100, limiteMedio: 85, limiteInferior: 70 }, // Pruebas Rapidas COVID-19
      { limiteSuperior: 100, limiteMedio: 85, limiteInferior: 70 }, // OK - Consultas de Nutricion
      { limiteSuperior: 100, limiteMedio: 90, limiteInferior: 75 }, // OK - Platicas Alimentacion Sana (Nutricion)
      { limiteSuperior: 100, limiteMedio: 90, limiteInferior: 75 }, // OK - Platicas Salud Bucal (Dental)
      { limiteSuperior: 100, limiteMedio: 90, limiteInferior: 75 }, // OK - Acciones Salud Bucodental
      { limiteSuperior: 100, limiteMedio: 80, limiteInferior: 60 }  // OK - Platicas de Promocion a la Salud
    ]
  }),
  methods: {
    getBackgroundColorFromValue(valor, posicion) {
      const { limiteSuperior, limiteMedio, limiteInferior } = this.limites[posicion];
      if (valor >= limiteSuperior) {
          return "#0C769E";  // Mayor o igual al límite superior
      } else if (valor >= limiteMedio && valor < limiteSuperior) {
          return "#3C7D22";  // Mayor o igual al límite medio pero menor que el límite superior
      } else if (valor >= limiteInferior && valor < limiteMedio) {
          return "#FFD54F";  // Mayor o igual al límite inferior pero menor que el límite medio
      } else {
          return "#C00000";  // Menor que el límite inferior
      }
    },
    getTextColorFromValue(valor, posicion) {
      const { limiteSuperior, limiteMedio, limiteInferior } = this.limites[posicion];
      if (valor >= limiteSuperior) {
          return "#FFFFFF";  // Mayor o igual al límite superior
      } else if (valor >= limiteMedio && valor < limiteSuperior) {
          return "#FFFFFF";  // Mayor o igual al límite medio pero menor que el límite superior
      } else if (valor >= limiteInferior && valor < limiteMedio) {
          return "#000000";  // Mayor o igual al límite inferior pero menor que el límite medio
      } else {
          return "#FFFFFF";  // Menor que el límite inferior
      }
    },
    updateInfomation(item){
      this.show = false;
      var template = Object.assign({}, this.defaultViewItem);
      this.viewItem = Object.assign(template, item);
      this.$nextTick(() => {
          this.show = true;
      });
    }
  }
}
</script>