<template>
  <div class="pa-3 ma-3">
    <v-row>
      <v-col cols="3"></v-col>
      <v-col cols="6">
        <ReporteTiempoReal>
        </ReporteTiempoReal>
      </v-col>
      <v-col cols="3"></v-col>
    </v-row>
  </div>
</template>

<script>
import ReporteTiempoReal from './Reportes/TiempoReal/ReporteTiempoReal.vue'
//import { mapState } from 'vuex'

export default {
  computed: {
    //...mapState(['ids_vistas'])
  },
  components:{
    ReporteTiempoReal
  },
  data: () => ({
  }),
  methods: {
    /*
    mostrarVista(id_vista){
			if(this.ids_vistas == null || this.ids_vistas.length == 0) return false;
			return (this.ids_vistas.indexOf(id_vista) >= 0);
		}
    */
  }
}
</script>