<template>
  <v-row justify="center">
    <v-alert-dialog
      ref="alert"
    >
    </v-alert-dialog>
    <v-dialog
      v-model="dialogComplete"
      max-width="50%"
      persistent
    >
      <v-card>
        <v-card-text class="text-center">
          <v-icon
            size="300"
            color="green"
          >
            mdi-check-circle
          </v-icon>
          <div class="text-h4">
            Consulta Nutricional Guardada Correctamente
          </div>
          <br>
          <v-btn
            block
            color="primary"
            text
            @click="nuevaPestana(`/api/ConsultasNutricionales/ObtenerRecomendacionesNutricionales/${consulta_nutricional.id_paciente_servicio}?jwt=${token}`)"
          >
            <v-icon>
              mdi-download
            </v-icon>
            Descargar recomendaciones Nutricionales
          </v-btn>
          <br>   
          <v-btn
            block
            color="primary"
            text
            @click="nuevaPestana(`/api/ConsultasNutricionales/ObtenerEntrevistaNutricional/${consulta_nutricional.id_paciente_servicio}?jwt=${token}`)"
          >
            <v-icon>
              mdi-download
            </v-icon>
            Descargar Entrevista Nutricional
          </v-btn>
          <br>          
          <v-btn
            block
            color="green"
            class="white--text"
            @click="cerrarDialogo"
          >
            Aceptar
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      persistent
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="cancelarInicioConsulta">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Consulta Nutricional</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <br />
        <v-card-text>
          <v-row>
            <v-col cols="3">
              <v-row class="ma-0 pa-0 sticky-content">
                <v-col cols="12" class="ma-0 pa-0">
                  <PacienteDatos
                    :id_paciente_servicio="
                      consulta_nutricional.id_paciente_servicio
                    "
                    ref="paciente_datos"
                    @change="actualizarDatos"
                  ></PacienteDatos>
                </v-col>
                <v-col
                  cols="12"
                  v-if="
                    consulta_nutricional.num_consultas_medicas > 1 ||
                    consulta_nutricional.num_consultas_dentales > 1
                  "
                >
                  <v-btn block text color="primary" @click="abrirHistorial">
                    <v-icon> mdi-book-open-blank-variant-outline </v-icon>
                    Mostrar Historia Clinica
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="9">
              <v-row>
                <v-col cols="8">
                  <v-text-field
                    v-model="editedItem.domicilio"
                    label="Domicilio"
                    outlined
                    hide-details
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="editedItem.telefono"
                    label="Teléfono"
                    outlined
                    hide-details
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-autocomplete-add
                    v-model="editedItem.id_estado_civil"
                    label="Estado Civil"
                    item-value="id_estado_civil"
                    item-text="nombre"
                    url-get="/Catalogos/ListarEstadosCiviles"
                    url-save="/Catalogos/CrearEstadoCivil"
                    :hideAdd="true"
                  >
                  </v-autocomplete-add>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="editedItem.curp"
                    label="CURP"
                    outlined
                    hide-details
                    dense
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="4">
                  <v-autocomplete-add
                    v-model="editedItem.id_ocupacion"
                    label="Ocupación"
                    item-value="id_ocupacion"
                    item-text="nombre"
                    url-get="/Catalogos/ListarOcupaciones"
                    url-save="/Catalogos/CrearOcupacion"
                  >
                  </v-autocomplete-add>
                </v-col>
              </v-row>
              <br />
              <div class="text-subtitle-1 font-weight-bold text-center">
                Laboratorio
              </div>
              <br />
              <v-row>
                <v-col cols="4">
                  <v-text-field
                    v-model="editedItem.glucosa"
                    outlined
                    label="Glucosa"
                    dense
                    hide-details
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="editedItem.colesterol"
                    outlined
                    label="Colesterol"
                    dense
                    hide-details
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="editedItem.trigliceridos"
                    outlined
                    label="Triglicéridos"
                    dense
                    hide-details
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    v-model="editedItem.observaciones"
                    outlined
                    label="Observaciones"
                    auto-grow
                    rows="1"
                    dense
                    hide-details
                  >
                  </v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <span class="font-weight-bold"
                    >Antecedentes heredofamiliares</span
                  >
                  <v-row class="pa-1 ma-0">
                    <v-col cols="3" class="pa-0 ma-0"> Diabetes: </v-col>
                    <v-col cols="3" class="pa-0 ma-0">
                      <v-radio-group
                        v-model="editedItem.heredofamiliares_diabetes"
                        required
                        row
                        hide-details
                        dense
                        class="ma-0 pa-0"
                      >
                        <v-radio label="Si" :value="true"></v-radio>
                        <v-radio label="No" :value="false"></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-0">
                      <v-textarea
                        v-model="editedItem.heredofamiliares_diabetes_nota"
                        :rows="1"
                        auto-grow
                        outlined
                        hide-details
                        dense
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>

                  <v-row class="pa-1 ma-0">
                    <v-col cols="3" class="pa-0 ma-0"> Hipertensión: </v-col>
                    <v-col cols="3" class="pa-0 ma-0">
                      <v-radio-group
                        v-model="editedItem.heredofamiliares_hipertension"
                        required
                        row
                        hide-details
                        dense
                        class="ma-0 pa-0"
                      >
                        <v-radio label="Si" :value="true"></v-radio>
                        <v-radio label="No" :value="false"></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-0">
                      <v-textarea
                        v-model="editedItem.heredofamiliares_hipertension_nota"
                        :rows="1"
                        auto-grow
                        outlined
                        hide-details
                        dense
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>

                  <v-row class="pa-1 ma-0">
                    <v-col cols="3" class="pa-0 ma-0"> Cardiopatías: </v-col>
                    <v-col cols="3" class="pa-0 ma-0">
                      <v-radio-group
                        v-model="editedItem.heredofamiliares_cardiopatias"
                        required
                        row
                        hide-details
                        dense
                        class="ma-0 pa-0"
                      >
                        <v-radio label="Si" :value="true"></v-radio>
                        <v-radio label="No" :value="false"></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-0">
                      <v-textarea
                        v-model="editedItem.heredofamiliares_cardiopatias_nota"
                        :rows="1"
                        auto-grow
                        outlined
                        hide-details
                        dense
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>

                  <v-row class="pa-1 ma-0">
                    <v-col cols="3" class="pa-0 ma-0"> Respiratorias: </v-col>
                    <v-col cols="3" class="pa-0 ma-0">
                      <v-radio-group
                        v-model="editedItem.heredofamiliares_respiratorias"
                        required
                        row
                        hide-details
                        dense
                        class="ma-0 pa-0"
                      >
                        <v-radio label="Si" :value="true"></v-radio>
                        <v-radio label="No" :value="false"></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-0">
                      <v-textarea
                        v-model="editedItem.heredofamiliares_respiratorias_nota"
                        :rows="1"
                        auto-grow
                        outlined
                        hide-details
                        dense
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>

                  <v-row class="pa-1 ma-0">
                    <v-col cols="3" class="pa-0 ma-0"> Reumáticas: </v-col>
                    <v-col cols="3" class="pa-0 ma-0">
                      <v-radio-group
                        v-model="editedItem.heredofamiliares_reumaticas"
                        required
                        row
                        hide-details
                        dense
                        class="ma-0 pa-0"
                      >
                        <v-radio label="Si" :value="true"></v-radio>
                        <v-radio label="No" :value="false"></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-0">
                      <v-textarea
                        v-model="editedItem.heredofamiliares_reumaticas_nota"
                        :rows="1"
                        auto-grow
                        outlined
                        hide-details
                        dense
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>

                  <v-row class="pa-1 ma-0">
                    <v-col cols="3" class="pa-0 ma-0"> Neurológicas: </v-col>
                    <v-col cols="3" class="pa-0 ma-0">
                      <v-radio-group
                        v-model="editedItem.heredofamiliares_neurologicas"
                        required
                        row
                        hide-details
                        dense
                        class="ma-0 pa-0"
                      >
                        <v-radio label="Si" :value="true"></v-radio>
                        <v-radio label="No" :value="false"></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-0">
                      <v-textarea
                        v-model="editedItem.heredofamiliares_neurologicas_nota"
                        :rows="1"
                        auto-grow
                        outlined
                        hide-details
                        dense
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>

                  <v-row class="pa-1 ma-0">
                    <v-col cols="3" class="pa-0 ma-0"> Endocrinas: </v-col>
                    <v-col cols="3" class="pa-0 ma-0">
                      <v-radio-group
                        v-model="editedItem.heredofamiliares_endocrinas"
                        required
                        row
                        hide-details
                        dense
                        class="ma-0 pa-0"
                      >
                        <v-radio label="Si" :value="true"></v-radio>
                        <v-radio label="No" :value="false"></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-0">
                      <v-textarea
                        v-model="editedItem.heredofamiliares_endocrinas_nota"
                        :rows="1"
                        auto-grow
                        outlined
                        hide-details
                        dense
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>

                  <v-row class="pa-1 ma-0">
                    <v-col cols="3" class="pa-0 ma-0"> Cáncer: </v-col>
                    <v-col cols="3" class="pa-0 ma-0">
                      <v-radio-group
                        v-model="editedItem.heredofamiliares_cancer"
                        required
                        row
                        hide-details
                        dense
                        class="ma-0 pa-0"
                      >
                        <v-radio label="Si" :value="true"></v-radio>
                        <v-radio label="No" :value="false"></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-0">
                      <v-textarea
                        v-model="editedItem.heredofamiliares_cancer_nota"
                        :rows="1"
                        auto-grow
                        outlined
                        hide-details
                        dense
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>

                  <v-row class="pa-1 ma-0">
                    <v-col cols="3" class="pa-0 ma-0"> Alérgicos: </v-col>
                    <v-col cols="3" class="pa-0 ma-0">
                      <v-radio-group
                        v-model="editedItem.heredofamiliares_alergicos"
                        required
                        row
                        hide-details
                        dense
                        class="ma-0 pa-0"
                      >
                        <v-radio label="Si" :value="true"></v-radio>
                        <v-radio label="No" :value="false"></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-0">
                      <v-textarea
                        v-model="editedItem.heredofamiliares_alergicos_nota"
                        :rows="1"
                        auto-grow
                        outlined
                        hide-details
                        dense
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>

                  <v-row class="pa-1 ma-0">
                    <v-col cols="3" class="pa-0 ma-0"> Otros: </v-col>
                    <v-col cols="3" class="pa-0 ma-0">
                      <v-radio-group
                        v-model="editedItem.heredofamiliares_otros"
                        required
                        row
                        hide-details
                        dense
                        class="ma-0 pa-0"
                      >
                        <v-radio label="Si" :value="true"></v-radio>
                        <v-radio label="No" :value="false"></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-0">
                      <v-textarea
                        v-model="editedItem.heredofamiliares_otros_nota"
                        :rows="1"
                        auto-grow
                        outlined
                        hide-details
                        dense
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="6">
                  <span class="font-weight-bold">Antecedentes patológicos</span>
                  <v-row class="pa-1 ma-0">
                    <v-col cols="3" class="pa-0 ma-0"> Diabetes: </v-col>
                    <v-col cols="3" class="pa-0 ma-0">
                      <v-radio-group
                        v-model="editedItem.antecedentes_patologicos_diabetes"
                        required
                        row
                        hide-details
                        dense
                        class="ma-0 pa-0"
                      >
                        <v-radio label="Si" :value="true"></v-radio>
                        <v-radio label="No" :value="false"></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-0">
                      <v-textarea
                        v-model="
                          editedItem.antecedentes_patologicos_diabetes_nota"
                        :rows="1"
                        auto-grow
                        outlined
                        hide-details
                        dense
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>

                  <v-row class="pa-1 ma-0">
                    <v-col cols="3" class="pa-0 ma-0"> Hipertensión: </v-col>
                    <v-col cols="3" class="pa-0 ma-0">
                      <v-radio-group
                        v-model="
                          editedItem.antecedentes_patologicos_hipertension
                        "
                        required
                        row
                        hide-details
                        dense
                        class="ma-0 pa-0"
                      >
                        <v-radio label="Si" :value="true"></v-radio>
                        <v-radio label="No" :value="false"></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-0">
                      <v-textarea
                        v-model="
                          editedItem.antecedentes_patologicos_hipertension_nota
                        "
                        :rows="1"
                        auto-grow
                        outlined
                        hide-details
                        dense
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>

                  <v-row class="pa-1 ma-0">
                    <v-col cols="3" class="pa-0 ma-0"> Cardiopatías: </v-col>
                    <v-col cols="3" class="pa-0 ma-0">
                      <v-radio-group
                        v-model="
                          editedItem.antecedentes_patologicos_cardiopatias
                        "
                        required
                        row
                        hide-details
                        dense
                        class="ma-0 pa-0"
                      >
                        <v-radio label="Si" :value="true"></v-radio>
                        <v-radio label="No" :value="false"></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-0">
                      <v-textarea
                        v-model="
                          editedItem.antecedentes_patologicos_cardiopatias_nota
                        "
                        :rows="1"
                        auto-grow
                        outlined
                        hide-details
                        dense
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>

                  <v-row class="pa-1 ma-0">
                    <v-col cols="3" class="pa-0 ma-0"> Respiratorias: </v-col>
                    <v-col cols="3" class="pa-0 ma-0">
                      <v-radio-group
                        v-model="
                          editedItem.antecedentes_patologicos_respiratorias
                        "
                        required
                        row
                        hide-details
                        dense
                        class="ma-0 pa-0"
                      >
                        <v-radio label="Si" :value="true"></v-radio>
                        <v-radio label="No" :value="false"></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-0">
                      <v-textarea
                        v-model="
                          editedItem.antecedentes_patologicos_respiratorias_nota
                        "
                        :rows="1"
                        auto-grow
                        outlined
                        hide-details
                        dense
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>

                  <v-row class="pa-1 ma-0">
                    <v-col cols="3" class="pa-0 ma-0"> Reumáticas: </v-col>
                    <v-col cols="3" class="pa-0 ma-0">
                      <v-radio-group
                        v-model="editedItem.antecedentes_patologicos_reumaticas"
                        required
                        row
                        hide-details
                        dense
                        class="ma-0 pa-0"
                      >
                        <v-radio label="Si" :value="true"></v-radio>
                        <v-radio label="No" :value="false"></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-0">
                      <v-textarea
                        v-model="editedItem.antecedentes_patologicos_reumaticas_nota"
                        :rows="1"
                        auto-grow
                        outlined
                        hide-details
                        dense
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>

                  <v-row class="pa-1 ma-0">
                    <v-col cols="3" class="pa-0 ma-0"> Neurológicas: </v-col>
                    <v-col cols="3" class="pa-0 ma-0">
                      <v-radio-group
                        v-model="
                          editedItem.antecedentes_patologicos_neurologicas
                        "
                        required
                        row
                        hide-details
                        dense
                        class="ma-0 pa-0"
                      >
                        <v-radio label="Si" :value="true"></v-radio>
                        <v-radio label="No" :value="false"></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-0">
                      <v-textarea
                        v-model="
                          editedItem.antecedentes_patologicos_neurologicas_nota
                        "
                        :rows="1"
                        auto-grow
                        outlined
                        hide-details
                        dense
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>

                  <v-row class="pa-1 ma-0">
                    <v-col cols="3" class="pa-0 ma-0"> Endocrinas: </v-col>
                    <v-col cols="3" class="pa-0 ma-0">
                      <v-radio-group
                        v-model="editedItem.antecedentes_patologicos_endocrinas"
                        required
                        row
                        hide-details
                        dense
                        class="ma-0 pa-0"
                      >
                        <v-radio label="Si" :value="true"></v-radio>
                        <v-radio label="No" :value="false"></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-0">
                      <v-textarea
                        v-model="
                          editedItem.antecedentes_patologicos_endocrinas_nota
                        "
                        :rows="1"
                        auto-grow
                        outlined
                        hide-details
                        dense
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>

                  <v-row class="pa-1 ma-0">
                    <v-col cols="3" class="pa-0 ma-0"> Cáncer: </v-col>
                    <v-col cols="3" class="pa-0 ma-0">
                      <v-radio-group
                        v-model="editedItem.antecedentes_patologicos_cancer"
                        required
                        row
                        hide-details
                        dense
                        class="ma-0 pa-0"
                      >
                        <v-radio label="Si" :value="true"></v-radio>
                        <v-radio label="No" :value="false"></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-0">
                      <v-textarea
                        v-model="
                          editedItem.antecedentes_patologicos_cancer_nota
                        "
                        :rows="1"
                        auto-grow
                        outlined
                        hide-details
                        dense
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>

                  <v-row class="pa-1 ma-0">
                    <v-col cols="3" class="pa-0 ma-0"> Alérgicos: </v-col>
                    <v-col cols="3" class="pa-0 ma-0">
                      <v-radio-group
                        v-model="editedItem.antecedentes_patologicos_alergicos"
                        required
                        row
                        hide-details
                        dense
                        class="ma-0 pa-0"
                      >
                        <v-radio label="Si" :value="true"></v-radio>
                        <v-radio label="No" :value="false"></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-0">
                      <v-textarea
                        v-model="
                          editedItem.antecedentes_patologicos_alergicos_nota
                        "
                        :rows="1"
                        auto-grow
                        outlined
                        hide-details
                        dense
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>

                  <v-row class="pa-1 ma-0">
                    <v-col cols="3" class="pa-0 ma-0"> Otros: </v-col>
                    <v-col cols="3" class="pa-0 ma-0">
                      <v-radio-group
                        v-model="editedItem.antecedentes_patologicos_otros"
                        required
                        row
                        hide-details
                        dense
                        class="ma-0 pa-0"
                      >
                        <v-radio label="Si" :value="true"></v-radio>
                        <v-radio label="No" :value="false"></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-0">
                      <v-textarea
                        v-model="editedItem.antecedentes_patologicos_otros_nota"
                        :rows="1"
                        auto-grow
                        outlined
                        hide-details
                        dense
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="6">
                  <span class="font-weight-bold"
                    >Antecedentes personales no patológicos</span
                  >
                  <v-row class="pa-1 ma-0">
                    <v-col cols="3" class="pa-0 ma-0"> Alcoholismo: </v-col>
                    <v-col cols="3" class="pa-0 ma-0">
                      <v-radio-group
                        v-model="editedItem.antecedentes_personales_alcoholismo"
                        required
                        row
                        hide-details
                        dense
                        class="ma-0 pa-0"
                      >
                        <v-radio label="Si" :value="true"></v-radio>
                        <v-radio label="No" :value="false"></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-0">
                      <v-textarea
                        v-model="
                          editedItem.antecedentes_personales_alcoholismo_nota
                        "
                        :rows="1"
                        auto-grow
                        outlined
                        hide-details
                        dense
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>

                  <v-row class="pa-1 ma-0">
                    <v-col cols="3" class="pa-0 ma-0"> Tabaquismo: </v-col>
                    <v-col cols="3" class="pa-0 ma-0">
                      <v-radio-group
                        v-model="editedItem.antecedentes_personales_tabaquismo"
                        required
                        row
                        hide-details
                        dense
                        class="ma-0 pa-0"
                      >
                        <v-radio label="Si" :value="true"></v-radio>
                        <v-radio label="No" :value="false"></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-0">
                      <v-textarea
                        v-model="
                          editedItem.antecedentes_personales_tabaquismo_nota
                        "
                        :rows="1"
                        auto-grow
                        outlined
                        hide-details
                        dense
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>

                  <v-row class="pa-1 ma-0">
                    <v-col cols="3" class="pa-0 ma-0"> Toxicomanias: </v-col>
                    <v-col cols="3" class="pa-0 ma-0">
                      <v-radio-group
                        v-model="
                          editedItem.antecedentes_personales_toxicomanias
                        "
                        required
                        row
                        hide-details
                        dense
                        class="ma-0 pa-0"
                      >
                        <v-radio label="Si" :value="true"></v-radio>
                        <v-radio label="No" :value="false"></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-0">
                      <v-textarea
                        v-model="
                          editedItem.antecedentes_personales_toxicomanias_nota
                        "
                        :rows="1"
                        auto-grow
                        outlined
                        hide-details
                        dense
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>

                  <v-row class="pa-1 ma-0">
                    <v-col cols="3" class="pa-0 ma-0"> Escolaridad: </v-col>
                    <v-col cols="3" class="pa-0 ma-0">
                      <v-radio-group
                        v-model="editedItem.antecedentes_personales_escolaridad"
                        required
                        row
                        hide-details
                        dense
                        class="ma-0 pa-0"
                      >
                        <v-radio label="Si" :value="true"></v-radio>
                        <v-radio label="No" :value="false"></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-0">
                      <v-textarea
                        v-model="
                          editedItem.antecedentes_personales_escolaridad_nota
                        "
                        :rows="1"
                        auto-grow
                        outlined
                        hide-details
                        dense
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="6">
                  <span class="font-weight-bold">Situación Actual</span>
                  <v-row class="pa-1 ma-0">
                    <v-col cols="6" class="ma-0 pa-0 pr-2 align-self-center">
                      <v-text-field
                        v-model="editedItem.peso"
                        outlined
                        label="Peso (Kg)"
                        dense
                        hide-details
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-0 pl-2">
                      <v-radio-group
                        v-model="editedItem.nivel_obesidad"
                        dense
                        hide-details
                        class="ma-0 pa-0"
                      >
                        <v-radio label="Obesidad mórbida" value="1"></v-radio>
                        <v-radio label="Obesidad" value="2"></v-radio>
                        <v-radio label="Sobrepeso" value="3"></v-radio>
                        <v-radio label="Normal" value="4"></v-radio>
                        <v-radio label="Bajo de peso" value="5"></v-radio>
                        <v-radio label="Anémico" value="6"></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                    label="Diagnostico Nutricional"
                    v-model="editedItem.ids_diagnosticos_nutricionales"
                    outlined
                    :items="diagnosticoNutricionales"
                    item-value="id_diagnostico_nutricional"
                    item-text="diagnostico"
                    multiple
                    hide-details
                    chips
                    deletable-chips
                  >
                  </v-autocomplete>
                </v-col>
                <!--
                <v-col cols="12">
                  <v-textarea
                    v-model="editedItem.diagnostico"
                    label="Diagnóstico"
                    dense
                    hide-details
                    outlined
                    auto-grow
                    :rows="2"
                  >
                  </v-textarea>

                </v-col>
                -->
              </v-row>

              <v-row>
                <v-col cols="12">
                  <v-textarea
                    v-model="editedItem.recomendaciones"
                    label="Recomendación Nutricional (Incluye Dieta, Ejercicio entre Otros)"
                    dense
                    hide-details
                    outlined
                    auto-grow
                    :rows="2"
                  >
                  </v-textarea>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="6">
                  <consulta-checkbox
                    v-model="editedItem.consulta_medica"
                    :enable="!consulta_nutricional.consulta_medica"
                    consulta="Consulta Médica"
                    :outlined="true"
                    :dense="true"
                    :hide-details="true"
                  >
                  </consulta-checkbox>
                  <consulta-checkbox
                    v-model="editedItem.consulta_dental"
                    :enable="!consulta_nutricional.consulta_dental"
                    consulta="Consulta Dental"
                    :outlined="true"
                    :dense="true"
                    :hide-details="true"
                  >
                  </consulta-checkbox>
                </v-col>
                <v-col cols="6"></v-col>
              </v-row>

              <v-row>
                <v-col cols="3"></v-col>
                <v-col cols="6">
                  <v-card>
                    <v-container class="text-center font-weight-bold">
                      <TopazSignature
                        v-if="mostrar_firma"
                        ref="paciente"
                        id="paciente"
                      ></TopazSignature>
                      <div class="text-center text-subtitle-2">
                        {{ consulta_nutricional.paciente.nombre }}
                        {{ consulta_nutricional.paciente.apellido_paterno }}
                        {{ consulta_nutricional.paciente.apellido_materno }}
                      </div>
                    </v-container>
                  </v-card>
                </v-col>
                <v-col cols="3"></v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    @click="finalizarConsulta"
                    :loading="saving"
                    :disabled="saving"
                  >
                    Finalizar Consulta
                  </v-btn>
                  <br>
                  <br>
                  <br>
                  <br>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogHistorial" persistent>
      <v-card>
        <v-card-title> Historial Clinico </v-card-title>
        <v-card-text>
          <historial ref="historial"> </historial>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="green" @click="cerrarHistorial"> Cerrar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'
import axios from "axios";
import PacienteDatos from "@/components/DashViews/Comunes/PacienteDatosVertical.vue";
import VAutocompleteAdd from "@/components/VAutocompleteAdd.vue";
import Historial from "@/components/DashViews/Comunes/Historial.vue";
//import vueSignature from "vue-signature";
import TopazSignature from '@/components/TopazSignature.vue';
import ConsultaCheckbox from '@/components/ConsultaCheckbox.vue'

export default {
  components: {
    //vueSignature,
    TopazSignature,
    PacienteDatos,
    VAutocompleteAdd,
    Historial,
    ConsultaCheckbox
  },
  data: () => ({
    option: {
      penColor: "rgb(0, 0, 0)",
      backgroundColor: "rgba(0, 0, 0, 0)",
    },
    dialog: false,
    consulta_nutricional: {
      id_paciente_servicio: null,
      edad: 0,
      embarazada: false,
      id_estado_civil: null,
      id_ocupacion: null,
      glucosa: null,
      colesterol: null,
      trigliceridos: null,
      paciente: {
        id_paciente: null,
        id_municipio: null,
        municipio: "",
        id_estado: null,
        estado: "",
        id_localidad_atencion: null,
        localidad_atencion: "",
        folio: "",
        nombre: "",
        apellido_paterno: "",
        apellido_materno: "",
        fecha_nacimiento: "",
        edad: "",
        edad_anhios: 0,
        genero: true,
        curp: "",
        seguro_popular: false,
        num_poliza: "",
        telefono: "",
        email: "",
      },
      num_consultas_dentales: 0,
      num_consultas_medicas: 0,
      consulta_medica: false,
      consulta_dental: false
    },
    default_consulta_nutricional: {
      id_paciente_servicio: null,
      edad: 0,
      embarazada: false,
      id_estado_civil: null,
      id_ocupacion: null,
      glucosa: null,
      colesterol: null,
      trigliceridos: null,
      paciente: {
        id_paciente: null,
        id_municipio: null,
        municipio: "",
        id_estado: null,
        estado: "",
        id_localidad_atencion: null,
        localidad_atencion: "",
        folio: "",
        nombre: "",
        apellido_paterno: "",
        apellido_materno: "",
        fecha_nacimiento: "",
        edad: "",
        edad_anhios: 0,
        genero: true,
        curp: "",
        seguro_popular: false,
        num_poliza: "",
        telefono: "",
        email: "",
      },
      num_consultas_dentales: 0,
      num_consultas_medicas: 0,
      consulta_medica: false,
      consulta_dental: false
    },
    editedItem: {
      id_paciente_servicio: null,
      domicilio:  null,
      telefono:  null,
      id_estado_civil:  null,
      curp:  null,
      id_ocupacion:  null,
      glucosa:  null,
      colesterol:  null,
      trigliceridos:  null,
      observaciones:  null,
      heredofamiliares_diabetes:  null,
      heredofamiliares_diabetes_nota:  null,
      heredofamiliares_hipertension:  null,
      heredofamiliares_hipertension_nota:  null,
      heredofamiliares_cardiopatias:  null,
      heredofamiliares_cardiopatias_nota:  null,
      heredofamiliares_respiratorias:  null,
      heredofamiliares_respiratorias_nota:  null,
      heredofamiliares_reumaticas:  null,
      heredofamiliares_reumaticas_nota:  null,
      heredofamiliares_neurologicas:  null,
      heredofamiliares_neurologicas_nota:  null,
      heredofamiliares_endocrinas:  null,
      heredofamiliares_endocrinas_nota:  null,
      heredofamiliares_cancer:  null,
      heredofamiliares_cancer_nota:  null,
      heredofamiliares_alergicos:  null,
      heredofamiliares_alergicos_nota:  null,
      heredofamiliares_otros:  null,
      heredofamiliares_otros_nota:  null,
      antecedentes_patologicos_diabetes:  null,
      antecedentes_patologicos_diabetes_nota: null,
      antecedentes_patologicos_hipertension:  null,
      antecedentes_patologicos_hipertension_nota:  null,
      antecedentes_patologicos_cardiopatias:  null,
      antecedentes_patologicos_cardiopatias_nota:  null,
      antecedentes_patologicos_respiratorias:  null,
      antecedentes_patologicos_respiratorias_nota:  null,
      antecedentes_patologicos_reumaticas:  null,
      antecedentes_patologicos_reumaticas_nota:  null,
      antecedentes_patologicos_neurologicas:  null,
      antecedentes_patologicos_neurologicas_nota:  null,
      antecedentes_patologicos_endocrinas:  null,
      antecedentes_patologicos_endocrinas_nota:  null,
      antecedentes_patologicos_cancer:  null,
      antecedentes_patologicos_cancer_nota:  null,
      antecedentes_patologicos_alergicos:  null,
      antecedentes_patologicos_alergicos_nota:  null,
      antecedentes_patologicos_otros:  null,
      antecedentes_patologicos_otros_nota:  null,
      antecedentes_personales_alcoholismo:  null,
      antecedentes_personales_alcoholismo_nota:  null,
      antecedentes_personales_tabaquismo:  null,
      antecedentes_personales_tabaquismo_nota:  null,
      antecedentes_personales_toxicomanias: null,
      antecedentes_personales_toxicomanias_nota: null,
      antecedentes_personales_escolaridad:  null,
      antecedentes_personales_escolaridad_nota:  null,  
      peso:  null,
      nivel_obesidad:  null,
      ids_diagnosticos_nutricionales:  [],
      recomendaciones:  null,
      firma_paciente: null,
      consulta_medica: false,
      consulta_dental: false
    },
    defaultEditedItem: {
      id_paciente_servicio: null,
      domicilio:  null,
      telefono:  null,
      id_estado_civil:  null,
      curp:  null,
      id_ocupacion:  null,
      glucosa:  null,
      colesterol:  null,
      trigliceridos:  null,
      observaciones:  null,
      heredofamiliares_diabetes:  null,
      heredofamiliares_diabetes_nota:  null,
      heredofamiliares_hipertension:  null,
      heredofamiliares_hipertension_nota:  null,
      heredofamiliares_cardiopatias:  null,
      heredofamiliares_cardiopatias_nota:  null,
      heredofamiliares_respiratorias:  null,
      heredofamiliares_respiratorias_nota:  null,
      heredofamiliares_reumaticas:  null,
      heredofamiliares_reumaticas_nota:  null,
      heredofamiliares_neurologicas:  null,
      heredofamiliares_neurologicas_nota:  null,
      heredofamiliares_endocrinas:  null,
      heredofamiliares_endocrinas_nota:  null,
      heredofamiliares_cancer:  null,
      heredofamiliares_cancer_nota:  null,
      heredofamiliares_alergicos:  null,
      heredofamiliares_alergicos_nota:  null,
      heredofamiliares_otros:  null,
      heredofamiliares_otros_nota:  null,
      antecedentes_patologicos_diabetes:  null,
      antecedentes_patologicos_diabetes_nota: null,
      antecedentes_patologicos_hipertension:  null,
      antecedentes_patologicos_hipertension_nota:  null,
      antecedentes_patologicos_cardiopatias:  null,
      antecedentes_patologicos_cardiopatias_nota:  null,
      antecedentes_patologicos_respiratorias:  null,
      antecedentes_patologicos_respiratorias_nota:  null,
      antecedentes_patologicos_reumaticas:  null,
      antecedentes_patologicos_reumaticas_nota:  null,
      antecedentes_patologicos_neurologicas:  null,
      antecedentes_patologicos_neurologicas_nota:  null,
      antecedentes_patologicos_endocrinas:  null,
      antecedentes_patologicos_endocrinas_nota:  null,
      antecedentes_patologicos_cancer:  null,
      antecedentes_patologicos_cancer_nota:  null,
      antecedentes_patologicos_alergicos:  null,
      antecedentes_patologicos_alergicos_nota:  null,
      antecedentes_patologicos_otros:  null,
      antecedentes_patologicos_otros_nota:  null,
      antecedentes_personales_alcoholismo:  null,
      antecedentes_personales_alcoholismo_nota:  null,
      antecedentes_personales_tabaquismo:  null,
      antecedentes_personales_tabaquismo_nota:  null,
      antecedentes_personales_escolaridad:  null,
      antecedentes_personales_escolaridad_nota:  null,  
      peso:  null,
      nivel_obesidad:  null,
      ids_diagnosticos_nutricionales:  [],
      recomendaciones:  null,
      firma_paciente: null,
      consulta_medica: false,
      consulta_dental: false
    },
    dialogHistorial: false,
    validaciones: [
      { nombre: "Domicilio", campo: "domicilio", tipo: "string" },
      //{ nombre: "Teléfono", campo: "telefono", tipo: "string" },
      //{ nombre: "CURP", campo: "curp", tipo: "string" },
      //{ nombre: "Glucosa", campo: "glucosa", tipo: "string" },
      //{ nombre: "Colesterol", campo: "colesterol", tipo: "string" },
      //{ nombre: "Trigliceridos", campo: "trigliceridos", tipo: "string" },
      //{ nombre: "Observaciones", campo: "observaciones", tipo: "string" },
      {
        nombre: "Antecedentes heredofamiliares - Diabetes",
        campo: "heredofamiliares_diabetes",
        tipo: "boolean",
        nota: true,
        valor_nota: true,
      },
      {
        nombre: "Antecedentes heredofamiliares - Hipertensión",
        campo: "heredofamiliares_hipertension",
        tipo: "boolean",
        nota: true,
        valor_nota: true,
      },
      {
        nombre: "Antecedentes heredofamiliares - Cardiopatías",
        campo: "heredofamiliares_cardiopatias",
        tipo: "boolean",
        nota: true,
        valor_nota: true,
      },
      {
        nombre: "Antecedentes heredofamiliares - Respiratorias",
        campo: "heredofamiliares_respiratorias",
        tipo: "boolean",
        nota: true,
        valor_nota: true,
      },
      {
        nombre: "Antecedentes heredofamiliares - Reumáticas",
        campo: "heredofamiliares_reumaticas",
        tipo: "boolean",
        nota: true,
        valor_nota: true,
      },
      {
        nombre: "Antecedentes heredofamiliares - Neurológicas",
        campo: "heredofamiliares_neurologicas",
        tipo: "boolean",
        nota: true,
        valor_nota: true,
      },
      {
        nombre: "Antecedentes heredofamiliares - Endocrinas",
        campo: "heredofamiliares_endocrinas",
        tipo: "boolean",
        nota: true,
        valor_nota: true,
      },
      {
        nombre: "Antecedentes heredofamiliares - Cáncer",
        campo: "heredofamiliares_cancer",
        tipo: "boolean",
        nota: true,
        valor_nota: true,
      },
      {
        nombre: "Antecedentes heredofamiliares - Alérgicos",
        campo: "heredofamiliares_alergicos",
        tipo: "boolean",
        nota: true,
        valor_nota: true,
      },
      {
        nombre: "Antecedentes heredofamiliares - Otros",
        campo: "heredofamiliares_otros",
        tipo: "string",
      },
      {
        nombre: "Antecedentes patológicos - Diabetes",
        campo: "antecedentes_patologicos_diabetes",
        tipo: "boolean",
        nota: true,
        valor_nota: true,
      },
      {
        nombre: "Antecedentes patológicos - Hipertensión",
        campo: "antecedentes_patologicos_hipertension",
        tipo: "boolean",
        nota: true,
        valor_nota: true,
      },
      {
        nombre: "Antecedentes patológicos - Cardiopatías",
        campo: "antecedentes_patologicos_cardiopatias",
        tipo: "boolean",
        nota: true,
        valor_nota: true,
      },
      {
        nombre: "Antecedentes patológicos - Respiratorias",
        campo: "antecedentes_patologicos_respiratorias",
        tipo: "boolean",
        nota: true,
        valor_nota: true,
      },
      {
        nombre: "Antecedentes patológicos - Reumáticas",
        campo: "antecedentes_patologicos_reumaticas",
        tipo: "boolean",
        nota: true,
        valor_nota: true,
      },
      {
        nombre: "Antecedentes patológicos - Neurológicas",
        campo: "antecedentes_patologicos_neurologicas",
        tipo: "boolean",
        nota: true,
        valor_nota: true,
      },
      {
        nombre: "Antecedentes patológicos - Endocrinas",
        campo: "antecedentes_patologicos_endocrinas",
        tipo: "boolean",
        nota: true,
        valor_nota: true,
      },
      {
        nombre: "Antecedentes patológicos - Cáncer",
        campo: "antecedentes_patologicos_cancer",
        tipo: "boolean",
        nota: true,
        valor_nota: true,
      },
      {
        nombre: "Antecedentes patológicos - Alérgicos",
        campo: "antecedentes_patologicos_alergicos",
        tipo: "boolean",
        nota: true,
        valor_nota: true,
      },
      {
        nombre: "Antecedentes patológicos - Otros",
        campo: "antecedentes_patologicos_otros",
        tipo: "string",
      },
      {
        nombre: "Antecedentes personales no patológicos",
        campo: "antecedentes_personales_alcoholismo",
        tipo: "boolean",
        nota: true,
        valor_nota: true,
      },
      {
        nombre: "Antecedentes personales no patológicos",
        campo: "antecedentes_personales_tabaquismo",
        tipo: "boolean",
        nota: true,
        valor_nota: true,
      },
      {
        nombre: "Antecedentes personales no patológicos",
        campo: "antecedentes_personales_toxicomanias",
        tipo: "boolean",
        nota: true,
        valor_nota: true,
      },
      {
        nombre: "Antecedentes personales no patológicos",
        campo: "antecedentes_personales_escolaridad",
        tipo: "boolean",
        nota: true,
        valor_nota: true,
      },
      { nombre: "Peso", campo: "peso", tipo: "string" },
      { nombre: "IMC", campo: "nivel_obesidad", tipo: "string" },
      {
        nombre: "Recomendación Nutricional",
        campo: "recomendaciones",
        tipo: "string",
      },
    ],
    saving: false,
    dialogComplete: false,
    mostrar_firma: false,
    loadingDiagnosticosNutricionales: false,
    diagnosticoNutricionales: [],
  }),
  mounted() {
    this.getDiagnosticosNutricionales();
  },
  computed: {
    ...mapState(['token', 'extemporaneo']),
  },
  methods: {
    iniciarConsulta(id_paciente_servicio) {
      axios
        .post(`/ConsultasNutricionales/IniciarConsulta/${id_paciente_servicio}`)
        .then((response) => {
          this.consulta_nutricional = response.data;

          this.dialog = true;

          setTimeout(() => {
            this.$refs.paciente_datos.actualizar(id_paciente_servicio);
            this.mostrar_firma = true;
          }, 250);


          setTimeout(() => {
            if (response.data.id_estado_civil != null) this.editedItem.id_estado_civil = response.data.id_estado_civil;
            else this.editedItem.id_estado_civil = null;

            if (response.data.id_ocupacion != null) this.editedItem.id_ocupacion = response.data.id_ocupacion;
            else this.editedItem.id_ocupacion = null;

            if (response.data.glucosa != null) this.editedItem.glucosa = response.data.glucosa;
            else this.editedItem.glucosa = null;
            
            if (response.data.colesterol != null) this.editedItem.colesterol = response.data.colesterol;
            else this.editedItem.colesterol = null;

            if (response.data.trigliceridos != null) this.editedItem.trigliceridos = response.data.trigliceridos;
            else this.editedItem.trigliceridos = null;

            if (response.data.quimicas_secas_observaciones != null) this.editedItem.observaciones = response.data.quimicas_secas_observaciones;
            else this.editedItem.observaciones = null;

            if (response.data.peso != null) this.editedItem.peso = response.data.peso;
            else this.editedItem.peso = null;

            if (response.data.domicilio != null && response.data.domicilio.length > 0) this.editedItem.domicilio = response.data.domicilio;
            else this.editedItem.domicilio = null;
          }, 500);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    cancelarInicioConsulta() {
      axios
        .post(
          `/ConsultasNutricionales/CancelarInicioConsulta/${this.consulta_nutricional.id_paciente_servicio}`
        )
        .then(() => {
          this.consulta_nutricional = Object.assign({},this.default_consulta_nutricional);
          this.editedItem = JSON.parse(JSON.stringify(this.defaultEditedItem));
          this.dialog = false;
          this.$emit("complete", null);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    actualizarDatos() {},
    abrirHistorial() {
      this.dialogHistorial = true;
      setTimeout(
        () =>
          this.$refs.historial.cambiarPaciente(
            this.consulta_nutricional.paciente.id_paciente
          ),
        100
      );
    },
    cerrarHistorial() {
      try {
        this.$refs.historial.limpiarHistorial();
      } catch (error) { 
          console.log(error);
      }
      this.dialogHistorial = false;
    },
    finalizarConsulta() {
      var valido = true;

      if (this.editedItem.id_ocupacion == null) {
        this.$refs.alert.setMessage(
          "",
          `Debe de indicar la Ocupación del Paciente`
        );
        this.$refs.alert.open();
        valido = false;
        return;
      }

      if (this.editedItem.id_estado_civil == null) {
        this.$refs.alert.setMessage(
          "",
          `Debe de indicar el Estado Civil del Paciente`
        );
        this.$refs.alert.open();
        valido = false;
        return;
      }

      for (let i = 0; i < this.validaciones.length; i++) {
        if (
          this.editedItem[this.validaciones[i].campo] == null ||
          (this.validaciones[i].tipo == "string" &&
            this.editedItem[this.validaciones[i].campo].length < 0)
        ) {
          valido = false;
          this.$refs.alert.setMessage(
            "",
            `El Campo ${this.validaciones[i].nombre} es Obligatorio`
          );
          this.$refs.alert.open();
          break;
        }

        if (
          this.validaciones[i].tipo == "boolean" &&
          this.validaciones[i].nota == true &&
          this.editedItem[this.validaciones[i].campo] ==
            this.validaciones[i].valor_nota &&
          (this.editedItem[this.validaciones[i].campo + "_nota"] == null ||
            this.editedItem[this.validaciones[i].campo + "_nota"].length < 0)
        ) {
          valido = false;
          this.$refs.alert.setMessage(
            "",
            `La Nota del Campo ${this.validaciones[i].nombre} es Obligatoria`
          );
          this.$refs.alert.open();
          break;
        }
      }

      if(this.editedItem.ids_diagnosticos_nutricionales == null || this.editedItem.ids_diagnosticos_nutricionales.length <= 0){
        valido = false;
        this.$refs.alert.setMessage("", "Falta Indicar los Diagnosticos Nutricionales");
        this.$refs.alert.open();
      }

      if(this.$refs.paciente.isEmpty() && !this.extemporaneo){
        valido = false;
        this.$refs.alert.setMessage("", "Falta la Firma del Paciente");
        this.$refs.alert.open();
      }

      if(!valido) return;

      this.editedItem.firma_paciente = (!this.extemporaneo) ? this.$refs.paciente.save() : null;
      this.editedItem.id_paciente_servicio = this.consulta_nutricional.id_paciente_servicio;

      this.saving = true;
      axios
      .post(`/ConsultasNutricionales/FinalizarConsulta`,this.editedItem)
      .then(() => {
        this.dialogComplete = true;
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        this.saving = false;
      })
    },
    cerrarDialogo(){
      this.consulta_nutricional = Object.assign({}, this.default_consulta_nutricional);
      this.editedItem = JSON.parse(JSON.stringify(this.defaultEditedItem));
      this.dialog = false;
      this.$refs.paciente.clear();
      this.mostrar_firma = false;
      this.dialogComplete = false;
      this.$emit('complete', null);
    },
    nuevaPestana(url) {
      window.open(url, '_blank');
    },
    getDiagnosticosNutricionales(){
      this.loadingDiagnosticosNutricionales= true;
      axios
        .get('/Catalogos/ListarDiagnosticosNutricionales')
        .then(response => {
          this.diagnosticoNutricionales = response.data;
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.loadingDiagnosticosNutricionales = false;
        })
    },
  },
};
</script>

<style scoped>
.sticky-content {
  position: sticky;
  top: 20px; /* Ajusta la posición según sea necesario */
}
</style>