<template>
  <div>
    <v-alert-dialog
      ref="alert"
    >
    </v-alert-dialog>
    <v-dialog
        v-model="dialogElemento"
        max-width="50%"
      >
        <v-card>
          <v-card-title>
            Agregar {{label}}
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="elementoEditedItem.nombre"
                  outlined
                  :label="label"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              color="green"
              class="white--text"
              @click="restablecerElemento"
              :loading="savingElemento"
            >
              Cancelar
            </v-btn>
            <v-btn
              text
              color="red"
              class="white--text"
              @click="guardarElemento"
              :loading="savingElemento"
            >
              Agregar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    <v-autocomplete
      v-if="!hideAdd"
      v-model="localValue"
      :items="elementos"
      :item-text="itemText"
      :item-value="itemValue"
      outlined
      :label="label"
      :loading="loadingElemento"
      :disabled="loadingElemento"
      append-outer-icon="mdi-plus"
      @click:append-outer="addElemento"
      @change="updateValue"
      dense
      hide-details
    >
    </v-autocomplete>
    <v-autocomplete
      v-else
      v-model="localValue"
      :items="elementos"
      :item-text="itemText"
      :item-value="itemValue"
      outlined
      :label="label"
      :loading="loadingElemento"
      :disabled="loadingElemento"
      @change="updateValue"
      dense
      hide-details
    >
    </v-autocomplete>
  </div>
</template>

<script>
import VAlertDialog from '@/components/VAlertDialog.vue'
import axios from "axios"

export default {
  name: "VAutocompleteAdd",
  props: {
    value: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: ""
    },
    itemValue: {
      type: String,
      default: ""
    },
    itemText: {
      type: String,
      default: "nombre"
    },
    urlSave: {
      type: String,
      default: ""
    },
    urlGet: {
      type: String,
      default: ""
    },
    hideAdd: {
      type: Boolean,
      default: false,
    }
  },
  watch: {
    value(newValue) {
      this.localValue = newValue;
    }
  },
  mounted(){
    this.getElementos();
  },
  components:{
    VAlertDialog,
  },
  data: () => ({
    localValue: null,
    elementos: [],
    loadingElemento: false,
    dialogElemento: false,
    elementoEditedItem: {
      nombre: null
    },
    defaultElementoEditedItem: {
      nombre: null
    },
    savingElemento: false,
  }),
  methods: {
    restablecerElemento(){
      this.elementoEditedItem = Object.assign({}, this.defaultElementoEditedItem);
      this.dialogElemento = false;
    },
    guardarElemento(){
      if(this.elementoEditedItem.nombre == null){
        this.$refs.alert.setMessage('', `Debe de indicar un nombre`);
        this.$refs.alert.open();
        return;
      }

      if(this.elementoEditedItem.nombre.length <= 0 || this.elementoEditedItem.length > 255 ){
        this.$refs.alert.setMessage('', `El nombre debe de tener entre 1 y 256 caracteres.`);
        this.$refs.alert.open();
        return;
      }

      this.savingElemento = true;
      axios
      .post(this.urlSave,{
        nombre: this.elementoEditedItem.nombre
      })
      .then(response => {
        this.getElementos();
        this.localValue = response.data;
        this.updateValue();
        this.restablecerElemento();
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.savingElemento = false;
      })
    },
    addElemento(){
      this.elementoEditedItem = Object.assign({}, this.defaultElementoEditedItem);
      this.dialogElemento = true;
    },
    getElementos(){
      this.loadingElemento = true;
      axios
      .get(this.urlGet)
      .then(response => {
        this.elementos = response.data;
      })
      .catch(error => [
        console.log(error)
      ])
      .finally(() => {
        this.loadingElemento = false;
      })
    },
    updateValue(){
      this.$emit('input', this.localValue);
    }
  }
}
</script>
