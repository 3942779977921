<template>
  <v-col :cols="cols" :md="md" class="py-2">
    <v-row class="pa-0 ma-0">
      <v-col md="auto" class="px-0">
        <v-checkbox
          v-model="localErrorState"
          class="mr-2"
          indeterminate
          :error="localErrorState === false"
          dense
          hide-details
        ></v-checkbox>
      </v-col>
      <v-col cols="11" class="px-0">
        <v-btn
          :color="localErrorState === true ? 'primary' : 'error'"
          outlined
          :href="localModel"
          target="_blank"
          block
        >
          {{label}}
        </v-btn>
      </v-col>

      <v-col
        cols="12"
        v-if="localErrorState === false"
        class="ma-0 pa-0 py-2"
      >
        <v-textarea
          :label="textareaLabel"
          dense
          outlined
          hide-details
          v-model="localTextareaModel"
          auto-grow
          rows="1"
        ></v-textarea>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
export default {
  name: "TextFieldWithCheckbox",
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number],
      required: false,
      default: null,
    },
    errorState: {
      type: Boolean,
      default: true,
    },
    textareaLabel: {
      type: String,
      default: "Anomalía",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    cols: {
      type: Number,
      default: 12,
    },
    md: {
      type: Number,
      default: 12,
    },
  },
  emits: ['update:model', 'update:errorState', 'update:textareaModel'],
  computed: {
    localErrorState: {
      get() {
        return this.errorState;
      },
      set(value) {
        this.$emit('update:errorState', value);
      }
    },
    localModel: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('update:model', value);
      }
    },
    localTextareaModel: {
      get() {
        return "";
      },
      set(value) {
        this.$emit('update:textareaModel', value);
      }
    }
  }
};
</script>
