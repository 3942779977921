<template>
  <v-btn
    :color="color"
    :text="text"
    @click="onClick"
    :disabled="saving"
    :loading="saving"
  >
    {{label}}
    <template v-slot:loader>
      <v-progress-circular
        indeterminate
        :width="2"
        :size="24"
        :color="colorLoading"
      ></v-progress-circular>
    </template>
  </v-btn>
</template>

<script>
export default {
  name: "VBtnSave",
  props: {
    label: {
      type: String,
      default: "Save"
    },
    text:{
      type: Boolean,
      default: false,
    },
    color:{
      type: String,
      default: "red",
    },
    colorLoading: {
      type: String,
      default: "red"
    },
    saving:{
      type: Boolean,
      default: false,
    }
  },
  methods:{
    onClick($event){
      this.$emit('click',$event);
    }
  }
};
</script>