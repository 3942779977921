<template>
  <div
    class="pa-3 ma-3"
  >
    <v-alert-dialog
      ref="alert"
    >
    </v-alert-dialog>
    <v-dialog
      max-width="65%"
      v-model="dialogAddEdit"
      persistent
    >
      <v-card>
        <v-card-title>
          {{ editedItem.id_proyecto === null ? "Crear" : "Editar" }} Proyecto
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="editedItem.codigo"
                label="Número de proyecto"
                outlined
                dense
                hide-details
              >
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="editedItem.nombre"
                label="Nombre"
                outlined
                dense
                hide-details
              >
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="editedItem.empresa_patrocinadora"
                label="Empresa Patrocinadora"
                outlined
                dense
                hide-details
              >
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-switch
                  v-model="editedItem.activo"
                  :label="editedItem.activo ? 'Activo': 'Deshabilitado'"
                  dense
                  hide-details
                ></v-switch>
            </v-col>

            <v-col cols="6">
              <v-menu
                ref="menu1"
                v-model="menu1"
                :close-on-content-click="false"
                :return-value.sync="editedItem.inicio"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="editedItem.inicio"
                    label="Inicio"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    dense
                    outlined
                    hide-details
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="editedItem.inicio"
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="green"
                    @click="menu1 = false"
                  >
                    Cancelar
                  </v-btn>
                  <v-btn
                    text
                    color="red"
                    @click="$refs.menu1.save(editedItem.inicio)"
                  >
                    Aceptar
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>


            <v-col cols="6">
              <v-menu
                ref="menu2"
                v-model="menu2"
                :close-on-content-click="false"
                :return-value.sync="editedItem.fin"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="editedItem.fin"
                    label="Fin"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    dense
                    outlined
                    hide-details
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="editedItem.fin"
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="green"
                    @click="menu2 = false"
                  >
                    Cancelar
                  </v-btn>
                  <v-btn
                    text
                    color="red"
                    @click="$refs.menu2.save(editedItem.fin)"
                  >
                    Aceptar
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green" text @click="closeDialogAddEdit">Cancelar</v-btn>
          <v-btn color="red" text @click="saveDialogAddEdit" :loading="loadingSave">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      max-width="65%"
      v-model="dialogDelete"
      persistent
    >
      <v-card>
        <v-card-title>
          Eliminar Proyecto
        </v-card-title>
        <v-card-text>
          ¿Está seguro de que desea eliminar el proyecto llamado "{{ editedItem.nombre }}"? Esta acción es permanente y no se puede revertir.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green" text @click="closeDialogDelete">Cancelar</v-btn>
          <v-btn color="red" text @click="saveDialogDelete" :loading="loadingDelete">Eliminar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      max-width="65%"
      v-model="dialogMeta"
      persistent
    >
      <v-card>
        <v-card-title>
          Metas para [{{editedItem.codigo}}] - {{editedItem.nombre}}
        </v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-row class="ma-0 pa-0">
              <v-col cols="4" class="ma-0 pa-0">
                <v-subheader>Consulta médica</v-subheader>
              </v-col>
              <v-col cols="8">
                <VueNumberInput
                  v-model="metas.consulta_medica"
                  :min="0"
                  controls
                  size="small"
                ></VueNumberInput>
              </v-col>
            </v-row>

            <v-row class="ma-0 pa-0">
              <v-col cols="4" class="ma-0 pa-0">
                <v-subheader>Intervenciones odontológicas</v-subheader>
              </v-col>
              <v-col cols="8">
                <VueNumberInput
                  v-model="metas.intervenciones_odontologicas"
                  :min="0"
                  controls
                  size="small"
                ></VueNumberInput>
              </v-col>
            </v-row>

            <v-row class="ma-0 pa-0">
              <v-col cols="4" class="ma-0 pa-0">
                <v-subheader>Consulta dental</v-subheader>
              </v-col>
              <v-col cols="8">
                <VueNumberInput
                  v-model="metas.consulta_dental"
                  :min="0"
                  controls
                  size="small"
                ></VueNumberInput>
              </v-col>
            </v-row>

            <v-row class="ma-0 pa-0">
              <v-col cols="4" class="ma-0 pa-0">
                <v-subheader>Detecciones químicas secas</v-subheader>
              </v-col>
              <v-col cols="8">
                <VueNumberInput
                  v-model="metas.detecciones_quimicas_secas"
                  :min="0"
                  controls
                  size="small"
                ></VueNumberInput>
              </v-col>
            </v-row>

            <v-row class="ma-0 pa-0">
              <v-col cols="4" class="ma-0 pa-0">
                <v-subheader>T/A</v-subheader>
              </v-col>
              <v-col cols="8">
                <VueNumberInput
                  v-model="metas.ta"
                  :min="0"
                  controls
                  size="small"
                ></VueNumberInput>
              </v-col>
            </v-row>

            <v-row class="ma-0 pa-0">
              <v-col cols="4" class="ma-0 pa-0">
                <v-subheader>Somatometría</v-subheader>
              </v-col>
              <v-col cols="8">
                <VueNumberInput
                  v-model="metas.somatometria"
                  :min="0"
                  controls
                  size="small"
                ></VueNumberInput>
              </v-col>
            </v-row>

            <v-row class="ma-0 pa-0">
              <v-col cols="4" class="ma-0 pa-0">
                <v-subheader>Ultrasonidos</v-subheader>
              </v-col>
              <v-col cols="8">
                <VueNumberInput
                  v-model="metas.ultrasonidos"
                  :min="0"
                  controls
                  size="small"
                ></VueNumberInput>
              </v-col>
            </v-row>

            <v-row class="ma-0 pa-0">
              <v-col cols="4" class="ma-0 pa-0">
                <v-subheader>Electrocardiogramas</v-subheader>
              </v-col>
              <v-col cols="8">
                <VueNumberInput
                  v-model="metas.electrocardiogramas"
                  :min="0"
                  controls
                  size="small"
                ></VueNumberInput>
              </v-col>
            </v-row>

            <v-row class="ma-0 pa-0">
              <v-col cols="4" class="ma-0 pa-0">
                <v-subheader>Pruebas rápidas COVID-19</v-subheader>
              </v-col>
              <v-col cols="8">
                <VueNumberInput
                  v-model="metas.pruebas_rapidas_covid_19"
                  :min="0"
                  controls
                  size="small"
                ></VueNumberInput>
              </v-col>
            </v-row>

            <v-row class="ma-0 pa-0">
              <v-col cols="4" class="ma-0 pa-0">
                <v-subheader>Consultas de nutrición</v-subheader>
              </v-col>
              <v-col cols="8">
                <VueNumberInput
                  v-model="metas.consultas_de_nutricion"
                  :min="0"
                  controls
                  size="small"
                ></VueNumberInput>
              </v-col>
            </v-row>

            <v-row class="ma-0 pa-0">
              <v-col cols="4" class="ma-0 pa-0">
                <v-subheader>Pláticas alimentación sana (nutrición)</v-subheader>
              </v-col>
              <v-col cols="8">
                <VueNumberInput
                  v-model="metas.platicas_alimentacion_sana_nutricion"
                  :min="0"
                  controls
                  size="small"
                ></VueNumberInput>
              </v-col>
            </v-row>

            <v-row class="ma-0 pa-0">
              <v-col cols="4" class="ma-0 pa-0">
                <v-subheader>Pláticas salud bucal (dental)</v-subheader>
              </v-col>
              <v-col cols="8">
                <VueNumberInput
                  v-model="metas.platicas_salud_bucal_dental"
                  :min="0"
                  controls
                  size="small"
                ></VueNumberInput>
              </v-col>
            </v-row>

            <v-row class="ma-0 pa-0">
              <v-col cols="4" class="ma-0 pa-0">
                <v-subheader>Acciones salud bucodental</v-subheader>
              </v-col>
              <v-col cols="8">
                <VueNumberInput
                  v-model="metas.acciones_salud_bocodental"
                  :min="0"
                  controls
                  size="small"
                ></VueNumberInput>
              </v-col>
            </v-row>

            <v-row class="ma-0 pa-0">
              <v-col cols="4" class="ma-0 pa-0">
                <v-subheader>Pláticas de promoción a la salud</v-subheader>
              </v-col>
              <v-col cols="8">
                <VueNumberInput
                  v-model="metas.platicas_de_promocion_a_la_salud"
                  :min="0"
                  controls
                  size="small"
                ></VueNumberInput>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green" text @click="closeDialogMetas">Cancelar</v-btn>
          <v-btn color="red" text @click="saveMetas" :loading="savingMetas">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row>
      <v-col cols="12">
        <v-btn
          color="primary"
          @click="openDialogAddEdit(null)"
        >
          Crear Proyecto
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-data-table
          :items="items"
          :headers="headers"
          :loading="loading"
        >
          <template v-slot:[`item.mostrar`]="{ item }">
            <v-icon
              v-if="item.mostrar"
              color="green"
            >
              mdi-check
            </v-icon>
            <v-icon
              v-else
              color="red"
            >
              mdi-close
            </v-icon>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="openDialogAddEdit(item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              small
              class="mr-2"
              @click="openDialogDelete(item)"
            >
              mdi-delete
            </v-icon>
            <v-icon
              small
              class="mr-2"
              @click="openDialogMetas(item)"
            >
              mdi-flag-checkered
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from 'axios'
import VAlertDialog from '@/components/VAlertDialog.vue';
import VueNumberInput from "@chenfengyuan/vue-number-input";

export default {
  components: {
    VAlertDialog,
    VueNumberInput
  },
  data: () => ({
    menu1: false,
    menu2: false,
    dialogAddEdit: false,
    loading: false,
    items: [],
    headers:[
      {
        text: "Disponible",
        value: "mostrar",
        align: "center",
        width: "15%"
      },
      {
        text: "Número de proyecto",
        value: "codigo",
        align: "center",
        width: "15%"
      },
      {
        text: "Proyecto",
        value: "nombre",
        align: "center",
        width: "20%"
      },
      {
        text: "Inicio del Proyecto",
        value: "inicio",
        align: "center",
        width: "12.5%"
      },
      {
        text: "Fin del Proyecto",
        value: "fin",
        align: "center",
        width: "12.5%"
      },
      {
        text: "Empresa Patrocinadora",
        value: "empresa_patrocinadora",
        align: "center",
        width: "15%"
      },
      {
        text: "Acciones",
        value: "actions",
        align: "center",
        width: "10%"
      }
    ],
    editedItem: {
      id_proyecto: null,
      nombre: "",
      codigo: "",
      empresa_patrocinadora: "",
      inicio: null,
      fin: null,
      activo: true
    },
    defaultEditedItem: {
      id_proyecto: null,
      nombre: "",
      codigo: "",
      empresa_patrocinadora: "",
      inicio: null,
      fin: null,
      activo: true
    },
    loadingSave: false,
    dialogDelete: false,
    loadingDelete: false,
    metas: {
      consulta_medica: 0,
      intervenciones_odontologicas: 0,
      consulta_dental: 0,
      detecciones_quimicas_secas: 0,
      ta: 0,
      somatometria: 0,
      ultrasonidos: 0,
      electrocardiogramas: 0,
      pruebas_rapidas_covid_19: 0,
      consultas_de_nutricion: 0,
      platicas_alimentacion_sana_nutricion: 0,
      platicas_salud_bucal_dental: 0,
      acciones_salud_bocodental: 0,
      platicas_de_promocion_a_la_salud: 0
    },
    defaultMetas: {
      consulta_medica: 0,
      intervenciones_odontologicas: 0,
      consulta_dental: 0,
      detecciones_quimicas_secas: 0,
      ta: 0,
      somatometria: 0,
      ultrasonidos: 0,
      electrocardiogramas: 0,
      pruebas_rapidas_covid_19: 0,
      consultas_de_nutricion: 0,
      platicas_alimentacion_sana_nutricion: 0,
      platicas_salud_bucal_dental: 0,
      acciones_salud_bocodental: 0,
      platicas_de_promocion_a_la_salud: 0
    },
    dialogMeta: false,
    loadingMeta: false,
    savingMetas: false
  }),
  mounted() {
    this.getItems();
  },
  methods: {
    getItems(){
      this.loading = true;
      axios
        .get('/Catalogos/ListarProyectos?solo_activos=false')
        .then(response => {
          this.items = response.data;
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        })
    },
    openDialogAddEdit(editItem = null){
      if(editItem != null) this.editedItem = Object.assign({}, editItem);
      else this.editedItem = Object.assign({}, this.defaultEditedItem);
      this.dialogAddEdit = true;
    },
    closeDialogAddEdit(){
      this.editedItem = Object.assign({}, this.defaultEditedItem);
      this.dialogAddEdit = false;
    },
    saveDialogAddEdit(){
      if (this.editedItem.codigo == null || this.editedItem.codigo.length === 0 || this.editedItem.codigo.length > 8) {
        this.$refs.alert.setMessage("", "Debe de Ingresar una Número de proyecto entre 1 y 8 caracteres");
        this.$refs.alert.open();
        return;
      }
      
      if (this.editedItem.nombre == null || this.editedItem.nombre.length === 0 || this.editedItem.nombre.length > 256) {
        this.$refs.alert.setMessage("", "Debe de Ingresar un Nombre entre 1 y 256 caracteres");
        this.$refs.alert.open();
        return;
      }

      this.loadingSave = true;

      axios({
        method: this.editedItem.id_proyecto === null ? 'POST' : 'PUT',
        url: this.editedItem.id_proyecto === null ? '/Catalogos/CrearProyecto' : `/Catalogos/EditarProyecto/${this.editedItem.id_proyecto}`,
        data: {
          codigo: this.editedItem.codigo,
          nombre: this.editedItem.nombre,
          empresa_patrocinadora: this.editedItem.empresa_patrocinadora,
          inicio: this.editedItem.inicio,
          fin: this.editedItem.fin,
          activo: this.editedItem.activo
        }
      })
      .then(() => {
        this.getItems();
        this.closeDialogAddEdit();
      })
      .catch(error => {
        if(error.response.status == 409){
          this.$refs.alert.setMessage("", "Existe un Proyecto con la misma Número de proyecto.");
          this.$refs.alert.open();
          return;
        }
      })
      .finally(() => {
        this.loadingSave = false;
      });
    },
    openDialogDelete(editItem){
      this.editedItem = Object.assign({}, editItem);
      this.dialogDelete = true;
    },
    closeDialogDelete(){
      this.editedItem = Object.assign({}, this.defaultEditedItem);
      this.dialogDelete = false;
    },
    saveDialogDelete(){
      this.loadingDelete = true;
      axios
        .delete(`/Catalogos/EliminarProyecto/${this.editedItem.id_proyecto}`)
        .then(() => {
          this.closeDialogDelete();
          this.getItems();
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loadingDelete = false;
        });
    },
    openDialogMetas(item){
      this.editedItem = Object.assign({}, item);
      this.dialogMeta = true;
      this.loadingMeta = true;
      axios
      .get(`/Catalogos/ListarProyectoMeta/${this.editedItem.id_proyecto}`)
      .then(response => {
        if(response.data != null && typeof response.data == 'object') this.metas = response.data;
        else this.metas = Object.assign({}, this.defaultMetas);
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.loadingMeta = false;
      })
    },
    closeDialogMetas(){
      this.editedItem = Object.assign({}, this.defaultEditedItem);
      this.metas = Object.assign({}, this.defaultMetas);
      this.loadingMeta = false;
      this.dialogMeta = false;
      this.savingMetas = false;
    },
    saveMetas(){
      this.savingMetas = true;

      axios
      .put(`/Catalogos/CrearActualizarProyectoMeta/${this.editedItem.id_proyecto}`,{
        consulta_medica: this.metas.consulta_medica,
        intervenciones_odontologicas: this.metas.intervenciones_odontologicas,
        consulta_dental: this.metas.consulta_dental,
        detecciones_quimicas_secas: this.metas.detecciones_quimicas_secas,
        ta: this.metas.ta,
        somatometria: this.metas.somatometria,
        ultrasonidos: this.metas.ultrasonidos,
        electrocardiogramas: this.metas.electrocardiogramas,
        pruebas_rapidas_covid_19: this.metas.pruebas_rapidas_covid_19,
        consultas_de_nutricion: this.metas.consultas_de_nutricion,
        platicas_alimentacion_sana_nutricion: this.metas.platicas_alimentacion_sana_nutricion,
        platicas_salud_bucal_dental: this.metas.platicas_salud_bucal_dental,
        acciones_salud_bocodental: this.metas.acciones_salud_bocodental,
        platicas_de_promocion_a_la_salud: this.metas.platicas_de_promocion_a_la_salud,
      })
      .then(() => {
        this.closeDialogMetas();
        this.getItems();
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.savingMetas = false;
      })
    }
  }
}
</script>
