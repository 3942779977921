<template>
  <v-container style="max-width: 1800px !important">
    <div class="pa-3 ma-3">
      <v-alert-dialog ref="alert"> </v-alert-dialog>

      <v-dialog
        v-model="dialogAddEdit"
        max-width="80%"
        persistent
      >
        <v-card>
          <v-card-title>
            {{ editedItem.id_empleado == null ? 'Agregar' : 'Editar' }} Empleado
          </v-card-title>
          <v-card-text>
            <v-row class="pa-0 ma-0">
              <v-col
                cols="12"
                class="pa-0 ma-0 text-center"
              >
                <div class="text-subtitle-1 font-weight-bold">
                  <v-icon>
                    mdi-account
                  </v-icon>
                  Datos Personales
                </div>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="editedItem.nombre"
                  label="Nombre"
                  outlined
                  dense
                  hide-details
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="editedItem.apellido_paterno"
                  label="Apellido Paterno"
                  outlined
                  dense
                  hide-details
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="editedItem.apellido_materno"
                  label="Apellido Materno"
                  outlined
                  dense
                  hide-details
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-datetime-picker
                  label="Fecha de Nacimiento"
                  v-model="editedItem.fecha_nacimiento"
                >
                </v-datetime-picker>
              </v-col>
              <v-col cols="4"
                style="align-content: center !important;"
              >
                <v-text-field
                  v-model="editedItem.telefono"
                  label="Telefono de Contacto"
                  outlined
                  dense
                  hide-details
                >
                </v-text-field>
              </v-col>
              <v-col cols="4"
                style="align-content: center !important;"
              >
                <v-text-field
                  v-model="editedItem.email"
                  label="Email de Contacto"
                  outlined
                  dense
                  hide-details
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-autocomplete
                  v-model="editedItem.id_estado_civil"
                  label="Estado Civil"
                  outlined
                  dense
                  hide-details
                  :items="estados_civiles"
                  item-value="id_estado_civil"
                  item-text="nombre"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="editedItem.curp_valor"
                  label="CURP"
                  outlined
                  dense
                  counter
                  maxlength="18"
                  :rules="curpRules"
                  @input="buscarUsuario"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  v-model="editedItem.id_puesto"
                  label="Puesto"
                  outlined
                  dense
                  hide-details
                  item-value="id_puesto"
                  item-text="nombre"
                  :items="puestos"
                >
                </v-autocomplete>
              </v-col>
              <template
                v-if="editedItem.id_empleado == null && editedItem.curp_valor != null && editedItem.curp_valor.length == 18"
              >
                <v-col
                  cols="12"
                  class="pa-0 ma-0 text-center"
                >
                  <br>
                  <div class="text-subtitle-1 font-weight-bold">
                    <v-icon>
                      mdi-lock-outline
                    </v-icon>
                    Acceso al Sistema
                  </div>
                  <br>
                </v-col>
                <v-col cols="12" v-if="loadingUser">
                  <div
                    class="text-center"
                    >
                    <v-progress-circular
                      :size="50"
                      color="primary"
                      indeterminate
                    ></v-progress-circular>
                    <br>
                    <br>
                    <div class="text-subtitle'1">Buscando Usuario</div>
                  </div>
                </v-col>
                <v-col cols="12" v-else class="text-body-1 text-center">
                  <template v-if="editedItem.crear_usuario">
                    No se ha detectado ningún usuario en el sistema con la CURP proporcionada. Se creará un usuario nuevo para este empleado.
                  </template>
                  <template v-else>
                    Se ha detectado un usuario en el sistema con la CURP proporcionada. No es necesario crear un usuario para este empleado.
                  </template>
                </v-col>
              </template>
              
              <v-col
                v-if="expedienteRequisitos.length > 0"
                cols="12"
                class="pa-0 ma-0 text-center"
              >
                <br>
                <div class="text-subtitle-1 font-weight-bold">
                  <v-icon>
                    mdi-file-document-outline
                  </v-icon>
                  Documentación
                </div>
                Si cuenta con algún documento de este empleado puede proporcionarlo. Este paso es opcional.
                <br>
              </v-col>
              <template
                v-for="expediente_requisito in expedienteRequisitos"
              >
                <v-col
                  :key="expediente_requisito.codigo"
                  cols="6"
                >
                  <template v-if="expediente_requisito.tipo_documento == 1">
                    <v-file-input
                      v-model="editedItem[expediente_requisito.codigo]"
                      truncate-length="100"
                      outlined
                      dense
                      hide-details
                      :label="`${expediente_requisito.nombre}`"
                    ></v-file-input>
                    <div v-if="expediente_requisito.nota != null" class="text-caption note-style">Nota: {{expediente_requisito.nota}}</div>
                  </template>
                  <template v-else-if="expediente_requisito.tipo_documento == 2">
                    <v-text-field
                      v-model="editedItem[expediente_requisito.codigo]"
                      outlined
                      dense
                      hide-details
                      :label="`${expediente_requisito.nombre}`"
                    ></v-text-field>
                    <div v-if="expediente_requisito.nota != null" class="text-caption note-style">Nota: {{expediente_requisito.nota}}</div>
                  </template>
                  <template v-else-if="expediente_requisito.tipo_documento == 3">
                    <v-text-field
                      v-model="editedItem[expediente_requisito.codigo]"
                      outlined
                      dense
                      hide-details
                      :label="`${expediente_requisito.nombre}`"
                    ></v-text-field>
                    <div v-if="expediente_requisito.nota != null" class="text-caption note-style">Nota: {{expediente_requisito.nota}}</div>
                  </template>
                  <template v-else-if="expediente_requisito.tipo_documento == 4">
                    <v-text-field
                      v-model="editedItem[expediente_requisito.codigo]"
                      outlined
                      dense
                      hide-details
                      :label="`${expediente_requisito.nombre}`"
                    ></v-text-field>
                    <div v-if="expediente_requisito.nota != null" class="text-caption note-style">Nota: {{expediente_requisito.nota}}</div>
                  </template>
                </v-col>
              </template>

            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green"
              text
              @click="closeDialogAddEdit"
            >
              Cancelar
            </v-btn>
            <v-btn
              color="red"
              text
              @click="saveDialogAddEdit"
              :loading="loadingSave"
              class="white--text"
              >
                Guardar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="dialogView"
        max-width="80%"
        persistent
      >
        <v-card>
          <v-card-title>
            Detalles del Empleado
          </v-card-title>
          <v-card-text>
            <v-row class="pa-0 ma-0">
              <v-col
                cols="12"
                class="pa-0 ma-0 text-center"
              >
                <div class="text-subtitle-1 font-weight-bold">
                  <v-icon>
                    mdi-account
                  </v-icon>
                  Datos Personales
                </div>
              </v-col>

              <v-col cols="12">
                <v-row class="pa-0 ma-0">
                  <v-col cols="3" class="ma-0 pa-0 text-align-center">
                    <v-avatar
                      size="220"
                    >
                      <v-img
                        :src="editedItem.imagen_perfil"
                      >
                      </v-img>
                    </v-avatar>
                  </v-col>
                  <v-col cols="9" class="ma-0 pa-0">
                    <v-row class="pa-0 ma-0">
                      <v-col cols="4">
                      <div class="text-subtitle-2">Nombre:</div>
                      <div>{{ editedItem.nombre }}</div>
                    </v-col>
                    <v-col cols="4">
                      <div class="text-subtitle-2">Apellido Paterno:</div>
                      <div>{{ editedItem.apellido_paterno }}</div>
                    </v-col>
                    <v-col cols="4">
                      <div class="text-subtitle-2">Apellido Materno:</div>
                      <div>{{ editedItem.apellido_materno }}</div>
                    </v-col>
                    <v-col cols="4">
                      <div class="text-subtitle-2">Fecha de Nacimiento:</div>
                      <div>{{ editedItem.fecha_nacimiento }}</div>
                    </v-col>
                    <v-col cols="4">
                      <div class="text-subtitle-2">Teléfono de Contacto:</div>
                      <div>{{ editedItem.telefono }}</div>
                    </v-col>
                    <v-col cols="4">
                      <div class="text-subtitle-2">Email de Contacto:</div>
                      <div>{{ editedItem.email }}</div>
                    </v-col>
                    <v-col cols="4">
                      <div class="text-subtitle-2">Estado Civil:</div>
                      <div>{{ editedItem.nombre_estado_civil }}</div>
                    </v-col>
                    <v-col cols="4">
                      <div class="text-subtitle-2">CURP:</div>
                      <div>{{ editedItem.curp_valor }}</div>
                    </v-col>
                    <v-col cols="4">
                      <div class="text-subtitle-2">Puesto:</div>
                      <div>{{ editedItem.nombre_puesto }}</div>
                    </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>

              <v-col
                v-if="expedienteRequisitos.length > 0"
                cols="12"
                class="pa-0 ma-0 text-center"
              >
                <br>
                <div class="text-subtitle-1 font-weight-bold">
                  <v-icon>
                    mdi-file-document-outline
                  </v-icon>
                  Documentación
                </div>
                <br>
                <v-alert
                  v-if="editedItem.pendiente_carga"
                  dense
                  text
                  dark
                  color="yellow darken-4"
                >
                  Documentación Pendiente de Carga
                </v-alert>
                <v-alert
                  v-else-if="editedItem.expediente_validado"
                  dense
                  text
                  color="green"
                >
                  Documentación Validada por {{editedItem.expediente_validado_por}} desde {{editedItem.expediente_validado_fecha}}
                </v-alert>
                <v-alert
                  v-else-if="!editedItem.expediente_validado"
                  dense
                  text
                  color="red"
                >
                  Documentación Pendiente de Validación
                </v-alert>
              </v-col>
              <template v-if="!editedItem.pendiente_carga">
                <template
                v-for="expediente_requisito in expedienteRequisitosVisualizar"
                >
                  <v-col
                    :key="expediente_requisito.codigo"
                    cols="4"
                  >
                    <div class="text-subtitle-2">{{ expediente_requisito.nombre }}:</div>

                    <!-- <div>{{ editedItem[expediente_requisito.codigo] }}</div> -->
                    <div>
                      <v-btn
                        small
                        block
                        outlined
                        color="primary"
                      >
                        <v-icon>
                          mdi-download
                        </v-icon>
                        Descargar Documento
                      </v-btn>
                    </div>
                  </v-col>
                </template>
              </template>
              
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green"
              text
              @click="closeDialogView"
            >
              Cerrar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>


      <v-row>
        <v-col cols="12">
          <v-btn color="primary" @click="openDialogAddEdit(null)">
            Agregar Empleado
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-data-table
            :loading="loading"
            :headers="headers"
            :items="items"
          >
            <template v-slot:[`item.fotografia`]="{ item }">
              <v-avatar class="my-2">
                <v-img :src="item.imagen_perfil"></v-img>
              </v-avatar>
            </template>
            <template v-slot:[`item.expediente_validado`]="{ item }">
              <VTooltipIconV2
                v-if="item.pendiente_carga"
                color="gray"
                icon="mdi-timer-sand"
                :tooltip-text="`Pendiente Carga de Documentación`"
              >
              </VTooltipIconV2>

              <VTooltipIconV2
                v-else-if="!item.expediente_validado"
                color="red"
                icon="mdi-alert"
                :tooltip-text="`En Espera de Validación de Documentacion`"
              >
              </VTooltipIconV2>

              <VTooltipIconV2
                v-else
                color="green"
                icon="mdi-check"
                :tooltip-text="`Documentación Validada por ${item.expediente_validado_por} desde ${item.expediente_validado_fecha}`"
              >
              </VTooltipIconV2>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon
                class="mr-2"
                small
                @click="openDialogView(item)"
              >
                mdi-eye
              </v-icon>
              <v-icon
                class="mr-2"
                small
                @click="openDialogAddEdit(item)"
              >
                mdi-pencil
              </v-icon>

              <v-icon
                v-if="!item.pendiente_carga && !item.expediente_validado"
                class="mr-2"
                small
                @click="openDialogAddEdit(item)"
              >
                mdi-check-bold
              </v-icon>
           </template>
          </v-data-table>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import axios from 'axios'
import VAlertDialog from "@/components/VAlertDialog.vue";
import VDatetimePicker from '@/components/VDatetimePicker.vue'
import VTooltipIconV2 from '@/components/VTooltipIconV2.vue';

export default {
  components: {
    VAlertDialog,
    VDatetimePicker,
    VTooltipIconV2
  },
  data: () => ({
    dialogView: false,
    dialogAddEdit: false,
    loading: false,
    items: [
      {
        id_empleado: '550e8400-e29b-41d4-a716-446655440000',
        id_puesto: '83497160-f077-4036-92dc-ef8ae15c632d',
        codigo_empleado: "EMP001",
        nombre_puesto: "Operador polivalente",
        nombre: "Sandra",
        apellido_paterno: "López",
        apellido_materno: "Ramírez",
        fecha_nacimiento: "1985-04-12",
        telefono: "55-1234-5678",
        email: "sandra.lopez@example.com",
        id_estado_civil: 'beb5253b-399b-4212-8dbb-20bfd95d5160',
        nombre_estado_civil: "Soltero/a",
        curp_valor: 'LOSR850412MDFLPN03',
        pendiente_carga: false,
        expediente_validado: true,
        expediente_validado_fecha: '01-nov-2024',
        expediente_validado_por: 'Carlos Fernández',
        imagen_perfil: 'https://cdn.vuetifyjs.com/images/lists/3.jpg',
      },
      {
        id_empleado: '550e8400-e29b-41d4-a716-446655440003',
        id_puesto: '2fc9dc1f-75aa-4e9e-a256-eb7c325b6c66',
        codigo_empleado: "EMP002",
        nombre_puesto: "Auxiliar Polivalente",
        nombre: "Alejandro",
        apellido_paterno: "Gómez",
        apellido_materno: "Hernández",
        fecha_nacimiento: "1990-08-22",
        telefono: "55-9876-5432",
        email: "alejandro.gomez@example.com",
        id_estado_civil: '49737087-35b6-471f-89c9-011ec163c0ce',
        nombre_estado_civil: "Casado/a",
        curp_valor: 'GOHA900822HDFMRL06',
        pendiente_carga: true,
        expediente_validado: false,
        expediente_validado_fecha: null,
        expediente_validado_por: null,
        imagen_perfil: 'https://cdn.vuetifyjs.com/images/lists/2.jpg',
      },
      {
        id_empleado: '550e8400-e29b-41d4-a716-446655440006',
        id_puesto: '2011e3c5-fd1c-452b-94ff-8df9bfc62857',
        codigo_empleado: "EMP003",
        nombre_puesto: "Responsable de Contabilidad",
        nombre: "Eduardo",
        apellido_paterno: "Martínez",
        apellido_materno: "Pérez",
        fecha_nacimiento: "1987-12-14",
        telefono: "55-5555-1234",
        email: "eduardo.martinez@example.com",
        id_estado_civil: 'bf22ced0-e1e7-4cbd-9b9e-23434d03c844',
        nombre_estado_civil: "Divorciado/a",
        curp_valor: 'MAPE871214HDFRZD07',
        pendiente_carga: false,
        expediente_validado: true,
        expediente_validado_fecha: '05-nov-2024',
        expediente_validado_por: 'Ana López',
        imagen_perfil: 'https://cdn.vuetifyjs.com/images/lists/1.jpg',
      },
      {
        id_empleado: '550e8400-e29b-41d4-a716-446655440012',
        id_puesto: '28ac4534-23a7-45a9-b4fa-0c833e675cea',
        codigo_empleado: "EMP005",
        nombre_puesto: "Practicante",
        nombre: "Beatriz",
        apellido_paterno: "Sánchez",
        apellido_materno: "Mendoza",
        fecha_nacimiento: "1983-06-15",
        telefono: "55-7890-1234",
        email: "beatriz.sanchez@example.com",
        id_estado_civil: '4f4e72f6-d81e-4781-a491-a49a3d48d30c',
        nombre_estado_civil: "Viudo/a",
        curp_valor: 'SAMB830615MDFNRZ09',
        pendiente_carga: false,
        expediente_validado: true,
        expediente_validado_fecha: '06-nov-2024',
        expediente_validado_por: 'Laura Estrada',
        imagen_perfil: 'https://cdn.vuetifyjs.com/images/lists/4.jpg',
      },
      {
        id_empleado: '550e8400-e29b-41d4-a716-446655440015',
        id_puesto: 'b36e3ce3-0503-468e-beb1-02c8c5f2d919',
        codigo_empleado: "EMP006",
        nombre_puesto: "Área de la Salud (médico general, coordinador médico, enfermería)",
        nombre: "María",
        apellido_paterno: "Cruz",
        apellido_materno: "García",
        fecha_nacimiento: "1989-01-25",
        telefono: "55-4567-8901",
        email: "maria.cruz@example.com",
        id_estado_civil: '49737087-35b6-471f-89c9-011ec163c0ce',
        nombre_estado_civil: "Casado/a",
        curp_valor: 'CUGM890125MDFRLR05',
        pendiente_carga: false,
        expediente_validado: false,
        expediente_validado_fecha: null,
        expediente_validado_por: null,
        imagen_perfil: 'https://cdn.vuetifyjs.com/images/lists/5.jpg',
      },
    ],
    headers: [
      {
        text: "Fotografia",
        value: "fotografia",
        align: "center",
      },
      {
        text: "# Empleado",
        value: "codigo_empleado",
        align: "center",
      },
      {
        text: "Nombre",
        value: "nombre",
        align: "center",
      },
      {
        text: "Apellido Paterno",
        value: "apellido_paterno",
        align: "center",
      },
      {
        text: "Apellido Materno",
        value: "apellido_materno",
        align: "center",
      },
      {
        text: "Puesto",
        value: "nombre_puesto",
        align: "center",
      },
      {
        text: "CURP",
        value: "curp_valor",
        align: "center",
      },
      {
        text: "Expediente",
        value: "expediente_validado",
        align: "center",
      },
      {
        text: "Acciones",
        value: "actions",
        sortable: false,
        align: "center",
      },
    ],
    curpRules: [v => (v && v.length === 18) || 'La longitud de la CURP debe de ser de 18 caracteres'],
    editedItem: {
      id_empleado: null,
      id_puesto: null,
      codigo_empleado: null,
      nombre_puesto: null,
      nombre: null,
      apellido_paterno: null,
      apellido_materno: null,
      fecha_nacimiento: null,
      telefono: null,
      email: null,
      id_estado_civil: null,
      nombre_estado_civil: null,
      curp_valor: null,
      expediente_validado: false,
      expediente_validado_fecha: null,
      expediente_validado_por: null,
      imagen_perfil: null,
      id_usuario: null,
      crear_usuario: false,

      acta_nacimiento: null,
      caratula_banco: null,
      carta_antecedentes_no_penales: null,
      carta_recomendacion_1: null,
      carta_recomendacion_2: null,
      cedula_profesional: null,
      cedula_profesional_kardex: null,
      cedula_profesional_kardex_salud: null,
      certificado_covid: null,
      certificado_estudios_cedula_profesional_titulo: null,
      certificado_estudios_kardex_legalizado: null,
      certificado_medico: null,
      certificado_ultimo_grado: null,
      comprobante_domicilio: null,
      constancia_situacion_fiscal: null,
      curp: null,
      curriculum_vitae: null,
      fotografia_credencial: null,
      identificacion_oficial: null,
      kardex_legalizado: null,
      licencia_conducir: null,
      licencia_conducir_equipo_pesado: null,
      nombre_contacto_emergencia: null,
      nss_imss: null,
      solicitud_empleo: null,
      telefono_contacto_emergencia: null,
      titulo_cedula_posgrado: null,
      titulo_profesional: null,
      titulo_profesional_carta_pasante: null,
    },
    defaultEditedItem: {
      id_empleado: null,
      id_puesto: null,
      codigo_empleado: null,
      nombre_puesto: null,
      nombre: null,
      apellido_paterno: null,
      apellido_materno: null,
      fecha_nacimiento: null,
      telefono: null,
      email: null,
      id_estado_civil: null,
      nombre_estado_civil: null,
      curp_valor: null,
      expediente_validado: false,
      expediente_validado_fecha: null,
      expediente_validado_por: null,
      imagen_perfil: null,
      id_usuario: null,
      crear_usuario: false,

      acta_nacimiento: null,
      caratula_banco: null,
      carta_antecedentes_no_penales: null,
      carta_recomendacion_1: null,
      carta_recomendacion_2: null,
      cedula_profesional: null,
      cedula_profesional_kardex: null,
      cedula_profesional_kardex_salud: null,
      certificado_covid: null,
      certificado_estudios_cedula_profesional_titulo: null,
      certificado_estudios_kardex_legalizado: null,
      certificado_medico: null,
      certificado_ultimo_grado: null,
      comprobante_domicilio: null,
      constancia_situacion_fiscal: null,
      curp: null,
      curriculum_vitae: null,
      fotografia_credencial: null,
      identificacion_oficial: null,
      kardex_legalizado: null,
      licencia_conducir: null,
      licencia_conducir_equipo_pesado: null,
      nombre_contacto_emergencia: null,
      nss_imss: null,
      solicitud_empleo: null,
      telefono_contacto_emergencia: null,
      titulo_cedula_posgrado: null,
      titulo_profesional: null,
      titulo_profesional_carta_pasante: null,
    },
    loadingSave: false,
    urlBase: "/api/RecursosHumanos/DescargarDocumento/",
    estados_civiles: [
    ],
    loadingEstadosCiviles: false,
    loadingUser: false,

    puestos: [],
    loadingPuestos: false,
  }),
  mounted() {
    this.getItems();
    this.getPuestos();
    this.getEstadosCiviles();
  },
  methods: {
    getPuestos(){
      this.loadingPuestos = true;
      axios
      .get('/RecursosHumanos/ListarPuestos')
      .then(response => {
        this.puestos = response.data;
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.loadingPuestos = false;
      })
    },
    getEstadosCiviles(){
      this.loadingEstadosCiviles = true;
      axios
      .get('/Catalogos/ListarEstadosCiviles')
      .then(response => {
        this.estados_civiles = response.data.filter(x => x.estandar == true);
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.loadingEstadosCiviles = false;
      })
    },
    getItems() {
    },
    openDialogAddEdit(editItem = null) {
      if(editItem != null) this.editedItem = editItem;
      else this.editedItem = Object.assign({}, this.defaultEditedItem);
      this.dialogAddEdit = true;
    },
    closeDialogAddEdit() {
      this.editedItem = Object.assign({}, this.defaultEditedItem);
      this.dialogAddEdit = false;
    },
    validateForm() {
      return true;
    },
    saveDialogAddEdit() {
      if (!this.validateForm()) return;
    },
    downloadFile(fichero){
      var url = `/api/RecursosHumanos/DescargarDocumento/${fichero}?jwt=${this.$store.state.token}`;
      window.open(url, '_blank');
    },
    getRowClass(item) {
      return (item.motivo_bloqueo != null && item.motivo_bloqueo.length > 0) ? 'red-row' : '';
    },
    buscarUsuario(){
      if(this.editedItem.curp_valor == null || this.editedItem.curp_valor.length != 18) return;

      this.loadingUser = true;

      axios
      .get(`/Users/GetUserUnlinked/${this.editedItem.curp_valor}`)
      .then(response => {
        if(typeof response.data == 'string' && response.data && response.data.length == 36) {
          this.editedItem.id_usuario = response.data;
          this.editedItem.crear_usuario = false;
        }
        else {
          this.editedItem.id_usuario = null;
          this.editedItem.crear_usuario = true;
        }
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        this.loadingUser = false
      })
    },
    openDialogView(editItem) {
      this.editedItem = Object.assign({}, editItem);
      this.dialogView = true;
    },
    closeDialogView() {
      this.editedItem = Object.assign({}, this.defaultEditedItem);
      this.dialogView = false;
    },
  },
  computed: {
    expedienteRequisitos(){
      if(typeof this.editedItem.id_puesto == 'undefined'  || this.editedItem.id_puesto == null) return [];
      var idx = this.puestos.findIndex(x => x.id_puesto == this.editedItem.id_puesto);
      return (idx >= 0) ? this.puestos[idx].expediente_requisitos : [];
    },
    expedienteRequisitosVisualizar(){
      if(typeof this.editedItem.id_puesto == 'undefined'  || this.editedItem.id_puesto == null) return [];
      var idx = this.puestos.findIndex(x => x.id_puesto == this.editedItem.id_puesto);
      return (idx >= 0) ? this.puestos[idx].expediente_requisitos.filter(x => x.id_expediente_requisito != '58e817f0-9fd2-444b-975f-981f128c2366') : [];
    }
  }
};
</script>


<style scoped>
.red-row {
  background-color: red !important;
  color: white;
}
</style>