<template>
  <div>
    <v-text-field
      v-model="url"
      :label="label"
      :dense="dense"
      :hide-details="hideDetails"
      :outlined="outlined"
      :error-messages="localError"
      @input="validate"
      required
      :error="errorMessages != null && errorMessages.length > 0"
    ></v-text-field>
    <span v-if="errorMessages != null && errorMessages.length > 0" class="red--text">{{errorMessages}}</span>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: 'Google Maps URL', // Valor por defecto para el label
    },
    dense: {
      type: Boolean,
      default: false, // Valor por defecto si es denso o no
    },
    hideDetails: {
      type: Boolean,
      default: true, // Valor por defecto para ocultar detalles
    },
    outlined: {
      type: Boolean,
      default: false, // Valor por defecto para outline
    },
    errorMessages: {
      type: [String, Array],
      default: () => []
    },
  },
  data() {
    return {
      url: this.value,
      localError: [],
    };
  },
  watch: {
    value(newValue) {
      this.url = newValue;
    },
    url(newUrl) {
      this.$emit('input', newUrl);
    },
  },
  methods: {
    validate() {
      this.localError = [];
      if (!this.url) {
        this.localError.push('La URL es requerida');
      } else if (
        !/^(https:\/\/)?(www\.)?(google\.)?(com|mx|co|es|de|fr|it|uk)?\/maps|^https:\/\/maps\.app\.goo\.gl/.test(
          this.url
        )
      ) {
        this.localError.push('Debe ser una URL válida de Google Maps');
      }
    }
  },
};
</script>
