<template>
  <v-dialog
    v-model="show"
    max-width="500px"
    persistent
  >
    <v-card>
      <v-card-title>
        {{title}}
      </v-card-title>
      <v-card-text>
        {{message}}
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn :color="cancelColor" text @click="cancelEvent">{{cancelText}}</v-btn>
        <v-btn :color="acceptColor" text @click="acceptEvent">{{acceptText}}</v-btn> 
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  data: () => ({
    show:false,
    title: "",
    message: "",
    acceptText: "Accept",
    acceptColor:"red",
    cancelText: "Cancel",
    cancelColor: "green"
  }),
  mounted(){
    this.$emit('mounted');
  },
  methods:{
    setMessage(title, message){
      this.title = title;
      this.message = message;
    },
    acceptEvent(){
      this.$emit('accept');
      this.close();
    },
    cancelEvent(){
      this.$emit('cancel');
      this.close();
    },
    setText(accept, cancel){
      this.acceptText = accept;
      this.cancelText = cancel;
    },
    setColor(accept, cancel){
      this.acceptColor = accept;
      this.cancelColor = cancel;
    },
    open(){
      this.show = true;
    },
    close(){
      this.show = false;
    },
    reset(){
      this.title = "";
      this.message = "";
      this.acceptText = "Accept";
      this.cancelText = "Cancel";
      this.acceptColor = "red";
      this.cancelText = "green";
    }
  }
}
</script>