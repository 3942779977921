<template>
  <v-col :cols="cols" :md="md" class="py-2">
    <v-col cols="12" class="d-flex align-center pa-0 ma-0">
      <v-checkbox
        v-model="localErrorState"
        class="mr-2"
        indeterminate
        :error="localErrorState === false"
        dense
        hide-details
      ></v-checkbox>
      <v-btn
        color="primary"
        dense
        outlined
        readonly
        :error="localErrorState === false"
        @click="downloadDocument"
      >
        {{ label }}
      </v-btn>
    </v-col>

    <v-col
      cols="12"
      v-if="localErrorState === false"
      class="ma-0 pa-0 py-2"
    >
      <v-textarea
        :label="textareaLabel"
        dense
        outlined
        hide-details
        v-model="localTextareaModel"
        auto-grow
        rows="1"
      ></v-textarea>
    </v-col>
  </v-col>
</template>

<script>
export default {
  name: "DownloadButtonWithTextarea",
  props: {
    value: {
      type: String,
      required: false,
      default: null,
    },
    label: {
      type: String,
      required: true,
    },
    downloadUrl: {
      type: String,
      required: true,
    },
    textareaLabel: {
      type: String,
      default: "Anomalía",
    },
    errorState: {
      type: Boolean,
      default: true,
    },
    cols: {
      type: Number,
      default: 12,
    },
    md: {
      type: Number,
      default: 12,
    },
    token: {
      type: String,
      required: true,
    },
  },
  emits: ['update:textareaModel', 'update:errorState'],
  computed: {
    localErrorState: {
      get() {
        return this.errorState;
      },
      set(value) {
        this.$emit('update:errorState', value);
      }
    },
    localTextareaModel: {
      get() {
        return "";
      },
      set(value) {
        this.$emit('update:textareaModel', value);
      }
    }
  },
  methods: {
    downloadDocument() {
      const url = `${this.downloadUrl}/${this.value}?jwt=${this.token}`;
      window.open(url, '_blank');
    },
  },
};
</script>
