<template>
  <v-col :cols="cols" :md="md" class="py-2">
    <v-col cols="12" class="d-flex align-center pa-0 ma-0">
      <v-checkbox
        v-model="localErrorState"
        class="mr-2"
        indeterminate
        :error="errorState === false"
        dense
        hide-details
      ></v-checkbox>
      <v-autocomplete
        :label="label"
        :items="items"
        :item-text="itemText"
        :item-value="itemValue"
        dense
        outlined
        hide-details
        v-model="localModel"
        :readonly="readonly"
        :error="errorState === false"
      ></v-autocomplete>
    </v-col>

    <v-col
      cols="12"
      v-if="errorState === false"
      class="ma-0 pa-0 py-2"
    >
      <v-textarea
        :label="textareaLabel"
        dense
        outlined
        hide-details
        v-model="localTextareaModel"
        auto-grow
        rows="1"
      ></v-textarea>
    </v-col>
  </v-col>
</template>

<script>
export default {
  computed: {
    localErrorState: {
      get() {
        return this.errorState;
      },
      set(value) {
        this.$emit('update:errorState', value);
      }
    },
    localModel: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('update:model', value);
      }
    },
    localTextareaModel: {
      get() {
        return "";
      },
      set(value) {
        this.$emit('update:textareaModel', value);
      }
    }
  },

  name: "AutocompleteWithCheckbox",
  props: {
    label: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    value: {
      type: [Number, Array, String],
      required: false,
      default: null,
    },
    errorState: {
      type: Boolean,
      default: true,
    },
    textareaLabel: {
      type: String,
      default: "Anomalía",
    },
    readonly: {
      type: Boolean,
      default: true,
    },
    cols: {
      type: Number,
      default: 12,
    },
    md: {
      type: Number,
      default: 12,
    },
    itemText: {
      type: String,
      default: "text"
    },
    itemValue: {
      type: String,
      default: "value"
    },
  },
  emits: ['update:model', 'update:errorState', 'update:textareaModel'],
};
</script>
