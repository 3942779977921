<template>
  <v-container style="max-width: 1600px !important;">
    <v-alert-dialog
      ref="alert"
    >
    </v-alert-dialog>
    <v-dialog
      max-width="65%"
      v-model="dialogAddEdit"
      persistent
    >
      <v-card>
        <v-card-title>
          {{ editedItem.id_rol === -1 ? "Crear" : "Editar" }} Rol
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="editedItem.name"
                label="Nombre"
                outlined
                dense
                hide-details=""
              >
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                v-model="editedItem.ids_vistas"
                label="Vistas"
                :items="vistas"
                item-value="id_vista"
                item-text="nombre"
                dense
                hide-details
                outlined
                multiple
                chips
              >
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green" text @click="closeDialogAddEdit">Cancelar</v-btn>
          <v-btn color="red" text @click="saveDialogAddEdit" :loading="loadingSave">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      max-width="65%"
      v-model="dialogDelete"
      persistent
    >
      <v-card>
        <v-card-title>
          Eliminar Rol
        </v-card-title>
        <v-card-text>
          ¿Está seguro de que desea eliminar el rol llamado "{{ editedItem.name }}"? Esta acción es permanente y no se puede revertir.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green" text @click="closeDialogDelete">Cancelar</v-btn>
          <v-btn color="red" text @click="saveDialogDelete" :loading="loadingDelete">Eliminar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col cols="12">
        <v-btn
          color="primary"
          @click="openDialogAddEdit(null)"
        >
          Crear Rol
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :items="items"
          :headers="headers"
          :loading="loading"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="openDialogAddEdit(item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              small
              class="mr-2"
              @click="openDialogDelete(item)"
            >
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'
import VAlertDialog from '@/components/VAlertDialog.vue'

export default {
  components: {
    VAlertDialog
  },
  data: () => ({
    dialogAddEdit: false,
    loading: false,
    items: [],
    headers:[
      {
        text: "Rol",
        value: "name",
        align: "center",
        width: "60%"
      },
      {
        text: "Número de Usuarios",
        value: "num_users",
        align: "center",
        width: "30%"
      },
      {
        text: "Acciones",
        value: "actions",
        align: "center",
        width: "10%"
      }
    ],
    editedItem: {
      id_rol: -1,
      name: "",
      ids_vistas: []
    },
    defaultEditedItem: {
      id_rol: -1,
      name: "",
      ids_vistas: []
    },
    loadingSave: false,
    defaultErrores: {
      name: false
    },
    dialogDelete: false,
    loadingDelete: false,
    loadingVistas: false,
    vistas: []
  }),
  mounted() {
    this.getItems();
    this.getVistas();
  },
  methods: {
    getItems(){
      this.loading = true;
      axios
      .get('/Roles/ListarRoles')
      .then(response => {
        this.items = response.data;
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.loading = false;
      })
    },
    getVistas(){
      this.loadingVistas = true;
      axios
      .get('/Catalogos/ListarVistas')
      .then(response => {
        this.vistas = response.data;
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.loadingVistas = false;
      })
    },
    openDialogAddEdit(editItem = null){
      if(editItem != null) this.editedItem = Object.assign({}, editItem);
      else this.editedItem = Object.assign({}, this.defaultEditedItem);
      this.dialogAddEdit = true;
    },
    closeDialogAddEdit(){
      this.editedItem = Object.assign({}, this.defaultEditedItem);
      this.dialogAddEdit = false;
    },
    saveDialogAddEdit(){
      if (this.editedItem.name.length === 0 || this.editedItem.name.length > 256) {
        this.$refs.alert.setMessage('', `El nombre debe de tener entre 1 y 256 caracteres.`);
        this.$refs.alert.open();
        return;
      }

      if (this.editedItem.ids_vistas == null || this.editedItem.ids_vistas.length <= 0) {
        this.$refs.alert.setMessage('', `Debe de seleccionar al menos una vista.`);
        this.$refs.alert.open();
        return;
      }

      this.loadingSave = true;

      axios({
        method: this.editedItem.id_rol === -1 ? 'POST' : 'PUT',
        url: this.editedItem.id_rol === -1 ? '/Roles/CrearRol' : `/Roles/EditarRol/${this.editedItem.id_rol}`,
        data: {
          nombre: this.editedItem.name,
          ids_vistas: this.editedItem.ids_vistas
        }
      })
      .then(() => {
        this.getItems();
        this.closeDialogAddEdit();
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.loadingSave = false;
      });
    },
    openDialogDelete(editItem){
      this.editedItem = Object.assign({}, editItem);
      this.dialogDelete = true;
    },
    closeDialogDelete(){
      this.editedItem = Object.assign({}, this.defaultEditedItem);
      this.dialogDelete = false;
    },
    saveDialogDelete(){
      this.loadingDelete = true;
      axios
      .delete(`/Roles/EliminarRol/${this.editedItem.id_rol}`)
      .then(() => {
        this.closeDialogDelete();
        this.getItems();
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.loadingDelete = false;
      });
    }
  }
}
</script>
